/* 701, 751 빅마켓(~6/12) */
const vicData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX

    수박/탄산음료/폴로,챔피언 패션잡화브랜드 20%
    추 가 할 인
    
    전용카드
    회원만!
    ▶ 기간 : 5/30(수) ~ 6/12(수)
    ▶ 해당카드 :
    롯데마트&MAXX카드,
    LOCA for 롯데마트카드/롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가

    수박 전품목 20% 추가 할인 12,784~16,784원
    당도선별 수박(6~10kg 미만/통/국산)
    ※점별 운영 중량 상이
    ※ 해당카드 미적용시
    17,980~22,980원
    ※ 롯데/신한카드 결제시
    15,980~20,980원

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    탄산음료 전품목
    (콜라/사이다/혼합탄산음료)
    ※트레비 토닉워터 얼그레이 제외

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    폴로/챔피언 브랜드 의류(성인/아동)
    ※해당카드 미적용시 9,980~79,800원

    61월 한달 내내 이 가격 ※기간 : 6/1(토) ~ 6/30(일)

    3,500원 할인 캐나다산 카무트(2kg/캐나다산) 18,980원
    2,000원 할인  롤링롤링 오징어(150g/원산지 별도표기) 7,980원
  </div>
  `,
  `
  <div>
    3,000원 할인 어포튀각 9,980원
    (400g/원산지 별도표기)

    하림 더 건강한 두마리 치킨 (팩 : 두마리/닭고기 : 국내산) 15,980원

    2,000원 할인 하림 용가리 치킨 9,980원
    (1.2kg)
    
    1,000원 할인 큐원 올리고당 7,380원
    (1.2kg×2입)
    
    1+1 자임 곤약젤리 사과/
    패션후르츠(150g×10입) 각16,980원
    
    1,500원 할인 삼립 뉴 젤리뽀 7,480원
    (2.4kg)
    
    2,500원 할인 유키앤러브 파인애플
    케이크(25g×20입) 13,480원

    롯데카드/신한카드 1,000원 할인 상쾌환(3g×12입) 14,980원
    
    10,000원 할인 블루밍가든 누들볼 29,800원
    (4P)
    
    롯데카드/신한카드 3,000원 할인 싸이닉 엔조이 선에센스 9,980원
    (50ml×2)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    시원한 할인가로
    슬기로운 여름 준비!

    롯데카드/신한카드 2,000원 할인 미국산 체리 12,980원
    (900g/팩)
    
    제스프리 한판 골드키위 14,980원
    (12입/박스/뉴질랜드산)

    단 8일간, 핫프라이스!
    롯데카드/신한카드 50% 할인  한우 등심(100g/냉장/국내산) 3,990원
    ※기간 : 5/30(목)~6/6(목) ※2등급

    L.POINT 25& 할인 1등급 한우 국거리/불고기 2,835원
    (각 100g/냉장/국내산)

    L.POINT 25& 할인 호주산 곡물비육 부채살 1,935원
    (100g/냉장/호주산)
    
    L.POINT 25& 할인 캐나다산 돼지 삼겹살/목심 각1,260원
    (각 100g/냉장/캐나다산)
  </div>
  `,
  `
  <div>
    1,000원 할인 청수 물냉면 5,280원
    (720g)
    
    3,000원 할인 풀무원 함흥비빔냉면 3,980원
    2인(460g)

    롯데카드/신한카드 2,000원 할인 티오 아이스티 복숭아맛 17,980원
    (13g×150입)
    
    스페쇼우 젤리스트로우
    플러스(1,254g) 10,980원

    4,020원 할인 트레비 토닉워터
    얼그레이 3,960원

    2,000원 할인 티젠 콤부차 매실 10,980원
    (5g×30입)

    롯데카드/신한카드 35% 할인 캐나다 활 랍스터 12,935원
    (마리/냉장)

    L.POINT 5,000원 할인 미국산 냉동 차돌박이 39,800원
    (1.2kg/냉동/미국산)

    10,000원 할인 젠텍 14인치 터보펜 에어 서큘레이터/
    젠텍 20인치 공업용 선풍기 각79,800원

    순면 누빔 편백메밀베개
    (블루/차콜) 각9,980원
     
    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    해양수산부와 함께하는 수산대전20%

    수산대전20% 프리미엄 광어회 29,984원
    (500g 내외/팩/광어 : 국산)

    수산대전20% ASC인증 완도 활전복 19,840원
    (대/14미/10마리)

    수산대전20% 원양산 오징어 2,384원
    (마리/해동)
 
    수산대전20% 영광 법성포 굴비 27,984원
    (1.4kg/팩/냉동/국산)

    수산대전20% 국산 고등어필렛 8,784원
    (1kg/팩/냉동)

    수산대전20% 황태채(400g/팩/러시아산)/
    볶음용, 조림용멸치 각9,984원
    (각 500g/팩/멸치:국산)

    완숙 토마토 10,980원
    (2.4kg/팩/국산)

    1,000원 할인 옛날방앗간 미숫가루 8,980원
    (1.2kg/원산지 별도표기) 

    1,500원 할인 냉장 다진마늘 9,480원
    (800g/국산)

    GAP 파프리카 980원
    (1개/국산) 

    다다기오이 4,480원
    (10입/봉/국산)

    
    </div>
  `,
  `
  <div>
    롯데카드/신한카드 1,800원 할인 신선한 계란
    (특/30입/1.8kg/국산) 5,480원

    3,500원 할인 귀한 쌀 26,480원
    (10kg/국산)

    각 2,000원 할인 구운아몬드/구운 점보캐슈넛 11,980/6,980원
    (1kg/400g, 원산지 별도표기)

    물가안정 갈치/참조기 각3,600/1,400원
    (각 32미/180미/마리, 해동, 국산)
 
    L.POINT 5,000원 할인 생새우살 31-40 16,980원
    (908g/팩/냉동/베트남산)

    L.POINT 20% 할인 호주산 곡물비육 살치살 3,664원
    (100g/냉장/호주산)
    
    L.POINT 25% 할인 호주산 찜갈비 1,860원
    (100g/냉장/호주산)

    L.POINT 3,000원 할인 정다운 허브 훈제오리
    (600g/냉장/원산지 별도표기) 11,980원
    
    L.POINT 1,500원 할인 하림 동물복지 닭볶음탕 8,480원
    (1.3kg/냉장/국내산)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    L.POINT 4,000원 할인 양념 목심 칼집구이 23,980원
    (1.7kg/원산지 별도표기) 

    L.POINT 5,000원 할인 수원식 양념돼지 왕갈비 구이 24,980원
    (1.7kg/원산지 별도표기)

    L.POINT 50% 할인 풀무원 국산콩 와사비 나또 5,700원
    (44.5g×8입/원산지 별도표기)

    패밀리팩 초밥 24,980원
    (40P/팩/원산지 별도표기)

    씨푸드 쉐이크 우동 3,980원
    (팩/원산지 별도표기)

    핫 스파이시 윙봉 13,980원
    (팩/원산지 별도표기)

    날치알 새꼬막 비빔밥 8,980원
    (팩/원산지 별도표기)

    2,000원 할인 피크닉 마들렌 10,900원
    (20입/1팩/원산지 별도표기)

    각 1,000원 할인 RTC 피자 2종/파스타 1종 11,900~4,900원
    (듬뿍불고기/핫 페퍼로니)
    ※원산지 별도표기 ※빅마켓 영등포점에 한함
  </div>
  `,
  `
  <div>
    3,000/1,000원 할인 하림 닭가슴살 블랙페퍼(510g)/
    사조대림 로얄크랩(100g×2입) 9,480/4,480원

    1,000원 할인 백종원의 홍콩반점 해물육교자 6,980원
    (350g×2입)

    6,000원 할인 하림 치킨쌀너겟 9,980원
    (1.2 kg)

    각 2,000원 할인 쉐푸드 등심통돈까스(600g)/
    롯데 라퀴진 모짜렐라 치즈스틱(800g) 12,980/11,980원

    2,000원 할인 남가네 설악 추어탕 14,980원
    (450g×3입)

    각 2,000원 할인 문옥례 명인 전통 고추장/된장 15,580/11,980원
    (각 900g)

    각 2,000/1,000원 할인 움트리 생와사비(120g×2입)/
    육류n생와사비랑(120g×2입)/
    양념 초고추장(1kg×2입) 7,480/8,380/5,980원

    1,000원 할인 고기엔 참소스
    (800g×2입) 8,480원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  `,
  `
 <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    테이스티 말보로
    소비뇽 블랑(750ml) 19,800원

    커티삭 오리지널/프로히비션(각 700ml) 24,800/42,800원

    1,000원 할인 풀무원 다논 그릭 플레인(90g×10입) 4,680원

    롯데카드/신한카드 1,500원 할인 대파&베이컨 크림치즈(50g×4입) 5,480원

    롯데카드/신한카드 5,000원 할인 덴마크 산양 프로틴 고단백 14,480원
    슬라이스 치즈(220g×4입) 

    3,500원 할인 밀카 초코무 비스킷(40g×24입) 11,980원

    1,000원 할인 해태 홈런볼 초코(46g×9입) 7,980원
 
    2,500원 할인 액트투 전자렌지용 팝콘 버터맛 14,480원
    (1,404g)
  </div> 
  `,
  `
  <div>
    2,000원 할인 켈로그 고소한 현미 그래놀라 11,680원
    (500g×2입)

    롯데카드/신한카드 5,000원 할인 베지밀 17곡 한끼두유 11,980원
    (190ml×24입)

    롯데카드/신한카드 1,500원 할인 MAXX아메리카노 리얼블랙 1,980원

    2,000/1,000원 할인 사과당 애플밀크티/애플크러쉬 4,980/3,980원
    (각 18g×20입)

    종근당건강 전품목 3만원 이상
    5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령

    L.POINT 5,000원 할인 종근당건강 생유산균 락토핏 코어
    더블세트(60포×2입) 27,800원

    롯데카드/신한카드 1,000원 할인 독일 비타하임 발포 멀티비타민
    (4,500mg×80정) 8,980원

    4,000원 할인 뉴트리원 장용성 초임계 알티지
    오메가3 트리플(1,200mg×60캡슐) 21,980원

    5,000원 할인 내추럴박스 산양유프로틴
    (15g×42포) 29,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    
    롯데카드/신한카드 2,000원 할인 MAXX 프리미엄 화장지
    (40m×30롤) 17,980원

    L.POINT 2.500원 할인 아우라 피오니 블러썸 16,480원
    (5.5L)

    L.POINT 4,000원 할인 테크 베이킹+구연산 액체세제 11,980원
    (4.8L)

    2,000원 할인 히말리야 핑크솔트 아이스 15,980원
    (160g×5)

    2,000원 할인 미쟝센 퍼펙트
    오리지널 세럼 8,980원
    샴푸(900ml)

    2,000원 할인 밀크바오밥
    세라 바디워시
    베이비 파우더 
    (1,200ml) 11,980원
    
    L.POINT 최대 4,000원 할인 INTEX 플라밍고 라이드온/
    미니프레임풀(블루) 17,980/32,800원

    2,000원 할인 애견 쿨아이스 방석(스카이블루) 10,980원
  </div>
  `,
  `
  <div>
    락앤락 저장용품
    ※세부 품목은 매장에서 확인하세요
    전품목 할인!
    7,000원 할인 락앤락 클래식 밀폐용기 12P 14,980원
    
    3,000원 할인 니트릴 장갑 100매 7,980원
    (소/중/대)
    2,000원 할인 냉감 일체형 베게 10,980원
    1,000원 할인 마작 대방석(내추럴) 7,980원
    9,820원 할인 블랙야크 워크웨어
    반소매 카라티셔츠 29,980원
    5,000원 할인 SOUP 슬리브리스 포인트 원피스 34,800원
    5,000원 할인 비비안 숨쉬는 브라
    (2매입, M/L/XL) 29,800원
    5,000원 할인 나이키 코르테즈 파우치(블랙) 39,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
];

/* 718 maxx(~2/5) */
const maxxData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
    ·전단적용기간 : 2025. 1. 31(금) ~ 2025. 2. 12(수)
    롯데마트맥스 : 금천, 목포, 상무, 송천, 영등포, 창원중앙점

    맛 좋은 신선과 간편식 추천!
    명절 끝! 집밥 솔루션

    롯데카드/신한카드
    담양딸기(1kg/박스/국산)/스마트팜설향딸기(800g/박스/국산) 각4,000원 할인

    2개 이상 각 1,000원 할인
    바나나 전품목 2개 이상 구매시 각 1,000원 할인 ※교차구매 가능
    마운틴 바나나(1kg 내외/원산지 별도표기) 2개 이상 구매시 1개당 각1,980원
    ※1개 구매시 2,980원 ※점별 운영상품 상이 ※1인당 10개 이하 구매가능

    롯데카드/신한카드 5,000원 할인
    매일 상하 체다슬라이스(1.36kg/80매) 12,480원

    롯데카드/신한카드 2,000원 할인
    천일식품 생생 곤드레나물밥(1,032g) 6,480원

    롯데카드/신한카드 30% 할인
    패밀리팩 초밥(40P/팩/원산지 별도표기) 19,936원

    L.POINT 20% 할인
    호주산 곡물비육 부채살(100g/냉장/호주산) 2,224원

    3,000원 할인
    존쿡 델리미트 킬바사 부대찌개(800g) 6,980원

    네이블 오렌지(9~15입/봉/미국산) 16,980원

    L.POINT 10% 할인
    광어회(500g 내외/팩/원산지 별도표기) 35,982원

    2개 이상 40%
    노랑오리 마늘치킨 훈제슬라이스(400g/냉장/원산지 별도표기) 2개 이상 구매시 1개당 각4,740원
    ※1개 구매시 7,900원
    ※기간 : 2/1(토) ~ 2/12(수)

    생딸기 리코타샐러드(팩/원산지 별도표기) 8,980원

    롯데카드/신한카드 50% 할인
    손질 가자미(마리/해동/미국산) 690원
    ※한정물량으로 조기품절될 수 있습니다

    L.POINT 5,000원 할인
    요리하다 양념 돼지고추장/간장 불고기(각 2kg/냉장/원산지 별도표기) 16,800원

    2,000원 할인
    풀무원 김치우동(1,053g) 8,980원

    3,000원 할인
    동원 그릴리 랭거부어스트(500g) 5,980원

    3,000원 할인
    맛있는 오뚜기밥(210g×24입) 19,980원


    최대 3,000원 할인
    본고장 시원한 바지락칼국수(6인분)/본고장 정통 가쓰오우동(4인분)(1,283g/929.6g) 6,480원

    롯데카드/신한카드 5,000원 할인
    목우촌 크리스피 핫도그 라이트(960g) 9,980원

    2,000원 할인
    델몬트 팜앤홈(제주감귤) 4,980원

    집밥 반찬(상품별 상이) L.POINT 20%
    ※일부품목 제외
    ※델리코너에서 확인하세요

    L.POINT 2,000원 할인
    오징어젓/낙지젓(2kg/1kg, 통/냉장/원산지 별도표기) 19,980/16,980원

    2개 이상 40%
    하림 닭볶음탕(800g/냉장/국내산) 2개 이상 구매시 1개당 각4,740원
    ※1개 구매시 7,900원
    ※기간 : 2/1(토) ~ 2/12(수)

    1,000원 할인
    풀무원 요거트 그릭 플레인(90g×10입) 4,680원

    2,000원 할인
    코카콜라(215ml×30입) 18,480원

    한달 내내 이 가격 
    ▶기간 : 2/1(토) ~ 2/28(금)

    L.POINT 2,000원 할인 
    건망고(400g/봉/원산지 별도표기) 7,980원

    L.POINT 3,000원 할인 
    하림 IFF 동물복지 닭가슴살(2kg/냉동/국내산) 16,980원

    L.POINT 4,000원 할인 
    내추럴박스 정성 담은 흑염소진액(70ml×30포) 15,980원

    1,100원 할인
    롯데 ABC초콜릿 대용량 기획(565g) 8,480원

    발렌타인데이 선물 추천

    2,500원 할인
    린도볼 어쏘티드(337g) 14,480원

    5,500원 할인
    마즈 스니커즈 펀사이즈(880g) 11,980원

    1,000원 할인 -> 롯데카드/신한카드 3,000원 추가할인
    리터 스포트 미니베스트 셀러(600g) 13,980원

    3,000원 할인
    웨더스 오리지널 크리미 필링(1kg) 9,980원

    L.POINT 30%
    보시오 모스카토 다스티 루카9(750ml) 17,430원

    롯데/신한 제휴카드 결제 고객 대상 최대50% 놀라운 혜택!
    ▶ 기간 : 1/31(금) ~ 2/12(수)
    ▶ 해당카드 : 롯데마트&MAXX카드,
    LOCA for 롯데마트카드, 롯데마트맥스 신한카드

    냉장 밀키트 전품목 2개 이상 50%
    기간 : 1/31(금) ~ 2/2(일)
    ※교차구매 가능

    컵밥 4종 2개 이상 20% ※교차구매 가능

    인기 시즌 차 8종 30%

    ※정상가란? 2025년 1월 10일(금) ~ 1월 30일(목) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    스테비아 대추방울토마토(500g/팩/국산) 4,980원

    칠레산 블루베리(310g×2입) 9,980원

    양상추(1통/봉/국산) 2,180원

    무농약 새송이 버섯(600g/팩/국산) 2,780원

    꿀 고구마(1.5kg/봉/국산) 6,480원

    L.POINT 30%
    풀무원 드레싱 5종(각 495g~550g/원산지 별도표기) 4,536~4,746원

    1,500원 할인
    까다롭게 선별한 1+등급란(특란 30입/1.8kg/국산) 6,980원

    5,600원 할인
    귀한쌀(20kg/국산) 52,800원

    3,000원 할인
    이탈리아산 파로(1kg) 11,980원
    ※기간 : 2/1(토)~2/28(금)

    서리태(1.2kg/국산) 12,480원

    캐나다산 카무트(2kg) 18,980원

    L.POINT 2,000원 할인
    구운 아몬드(1kg) 11,980원
    
    L.POINT 2,000원 할인
    건자두(650g/봉/원산지 별도표기) 7,980원

    L.POINT 1,000원 할인
    건무화과(420g/봉/원산지 별도표기) 7,980원
    ※기간 : 2/1(토)~2/12(수)

    L.POINT 20% 할인
    남해안 생굴(100g/냉장/국산) 1,824원

    L.POINT 5,000원 할인
    생새우살 71-90(908g/팩/냉동/베트남산) 14,980원

    L.POINT 3,000원 할인
    광천파래식탁김(5g×36봉/원산지 별도표기) 9,980원

    조미오징어(500g/원산지 별도표기) 18,980원

    L.POINT 3,000원 할인
    국내산 대패삼겹살/국내산 삼겹 한입구이(각 1kg/냉동) 각19,980원

    2개 이상 각 6,000원 할인
    미국산 우삼겹 구이(1kg/냉장/미국산) 2개 이상 구매시 1개당 각21,980원
    ※1개 구매시 27,980원
    ※기간 : 2/1(토)~2/12(수)

    L.POINT 3,000원 할인
    국산 우삼겹/척아이롤 샤브샤브(각 1kg/냉동/미국산) 16,980/21,980원

    토마토 치즈 스파게티(팩/원산지 별도표기) 5,980원

    500원 할인 -> 롯데카드/신한카드 20% 추가할인
    Very Berry StrawBerry 전품목(원산지 별도표기) 1,992~25,992원 ※롯데마트맥스 상무점/목포점에 한함

    1,000원 할인
    미니버터크로와상(20입/1봉/원산지 별도표기) 7,900원
    ※롯데마트맥스 영등포/금천/송천/창원중앙점에 한함

    1,500원 할인
    빙그레 딸기맛 우유(240ml×8입) 6,980원

    2,000원 할인
    삼립 정통보름달(88g×8입) 7,980원

    1,000원 할인
    씨제이 햇반 흑미밥/잡곡밥(각 210g×12입) 13,380원

    2,000원 할인
    론카딘 마르게리타(356g)/4치즈 피자(308g) 각3,980원

    롯데카드/신한카드 2개 이상 50%
    페리카나 치킨양념 오리지널맛(300g) 2개 이상 구매시 1개당 각1,990원 ※1개 구매시 3,980원

    최대2,000원 할인
    모니니 엑스트라버진 올리브유(유기농)/모니니 발사믹 식초(각 500ml) 15,980/5,980원

    1,400원 할인
    오리온 초코송이(576g) 7,980원

    2,000원 할인
    말랑쫀득 리치젤리(800g) 9,980원

    최대2,500원 할인
    카스텔크렘 포지타노 레몬캔디(750g)/마더 네스트 프로폴리스 캔디(300g) 15,480/11,980원

    롯데카드/신한카드 4,000원 할인
    동서 맥스웰 커피믹스 오리지널(11.8g×200입) 14,480원

    1,500원 할인
    핫식스(250ml×12입) 6,480원

    3,020원 할인
    카프리썬 오렌지망고/정글/사파리/펀알람(각 250ml×12입) 각8,780원

    4,000원 할인
    토레타(900ml×12입) 11,980원

    롯데카드/신한카드 5,000원 할인
    뉴트리원 유산균 맥스(2g×100입) 11,980원

    L.POINT 5,000원 할인
    CMG제약 스키니랩 모로실 다이어트(3g×20포) 12,980원

    2+1
    아임비타 멀티비타민 이뮨플러스 7입(23.5g×7개) 15,980원

    국민맥주 바이젠(500ml×6입) 9,800원

    별빛청하 스파클링 변온잔(295ml×4) 7,080원

    1,000원 할인 -> 롯데카드/신한카드 3,000원 할인
    생활공감ON 천연펄프 3겹 화장지(30m×30롤) 9,880원

    2,000원 할인
    파워브라이트 액체세제(5L)/아로마뷰 매직퍼퓸(4L) 각8,980원

    3,000원 할인 -> 롯데카드/신한카드 2,000원 추가할인
    시린메드 검케어(125g×5입) 16,980원

    3,000원 할인
    레이비 산양유 바디워시(1150ml) 14,980원

    2,000원 할인
    코리아나 리턴 크림(100ml×2입) 17,980원

    최대20,000원 할인
    무자계 전기요 시리즈(싱글/더블) 49,800~69,800원

    5,000원 할인
    아디다스 트레이닝 더플백XS 34,800원

    5,000원 할인
    나일론 스판조거 팬츠 14,980원

    3,000원 할인
    비비안 제로라인 팬티(5매입)/브라(2매입) 12,980/16,980원

    크리미 투명 수세미/망사 소프트 수세미(각 10입) 2,980/3,280원

    썬터치 베이직 가스버너 19,980원

    로얄그래프톤 스템 찜곰솥(30cm) 26,980원

    스텐 바닥 3중 인덕션 4종 세트 39,800원

    비비안 햄라인 팬티(5매입) 15,980원

    ※정상가란? 2025년 1월 10일(금) ~ 2025년 1월 30일(목) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
];

/* 703,753 빅마켓(~6/26) */
const vicNewData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX 전용카드 회원만!
    미국산 초이스 소고기/즉석밥 20% 추가할인

    ▶ 기간 : 6/13(목) ~ 6/26(수)
    ▶ 해당카드 : 롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외                                      
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    미국산 초이스 소고기 20% 추가 할인

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    미국산 초이스 부채살/살치살 (각 100g/냉장/미국산) 1,584/3,584원
    ※해당카드 미적용시 1,980/4,480원

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    즉석밥 전품목(상품별 상이) 20% 추가 할인

    딱!! 100일간 이 가격 ※기간 : 6/10(월)~9/17(화)

    동물복지 백색 유정란 (대 30입/1.56kg/국내산) 1,500원 할인 7,980원
    건망고 (400g/베트남산) 2,000원 할인 7,980원

    L.POINT 2,000원 할인
    롱스틱 직화육포 (90g×2입/원산지 별도표기) 6,980원

  </div>
  `,
  `
  <div>
    스위트 마운틴 바나나 (1.2kg 내외/봉/원산지 별도표기) 2,780원

    1등급 국내산 돼지 앞다리(100g/냉장/국내산) 980원

    왕새우튀김 (팩/원산지 별도표기) 8,980원

    MAXX 1A 저지방우유(2.3L) 400원 할인 4,980원

    롯데 설레임 밀크쉐이크 (160ml×12입) 3,000원 할인 8,980원

    뮤엘올리바 카놀라유(2L) 1,000원 할인 8,980원

    크라운 초코하임(142g×5입) 2,000원 할인 7,480원

    토레타/파워에이드 마운틴 블라스트 (각 900ml×12입) 3,500원 할인 각11,480원

    L.POINT 4,000원 할인
    비비랩 가르시니아 플러스 (1,000mg×112정) 15,980원

    에고라운드 두피쿨링 민트 샴푸(1,000ml) 2,000원 할인 11,980원

    MAXX 테이크아웃 아이스컵 80P 세트 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    슬기로운 여름생활!

    롯데카드/신한카드 2,000원 할인
    AI선별 수박(6~10kg 미만/통/국산) 14,980~19,980원

    천도 복숭아(2kg/팩/국산) 12,980원

    롯데카드/신한카드 25% 할인
    1등급 국내산 돼지 삼겹살/목심 (각 100g/냉장/국내산) 각1,860원

    L.POINT 7,000원 할인 
    1등급 한우 차돌박이/갈비살 (700g/600g, 냉장, 국내산) 47,800/72,800원

    롯데카드/신한카드 50% 할인
    러시아 활 킹크랩 (100g/냉장/러시아산) 5,990원

    롯데카드/신한카드 2,000원 할인
    파스퇴르 쾌변 요구르트 (150ml×8입) 8,780원

  </div>
  `,
  `
  <div>
    1,000원 할인
    스페쇼우 젤리스트로우 플러스 (1,254g) 9,980원

    2팩 이상 20% 할인
    제주 삼다수 그린 (2L×6입/500ml×20입) 2팩 이상 구매시 1팩당 5,184/7,680원
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    풀무원 평양 물냉면(2,538g)/칠갑 얼음찬 메밀소바(804g) 2,000원 할인 8,480/5,480원

    롯데 스낵 버라이어티팩(894g) 2,000원 할인 11,980원

    기능성 반팔티셔츠/7부팬츠, 5부팬츠 3,000원 할인 12,980/14,980원

    MAXX 스탠다드 단목 양말 (6족, 남성/여성) 1,000원 할인 각7,980원
    ※일부 색상 조기 소진 가능
    ※기간 : 6/10(월)~9/17(화)

    주방 필수템!
    키친유 스텐 밀폐용기 2P(2.0L/3.6L) 최대 3,000원 할인 17,980/24,980원

    에찌모토 식과도 가위세트(3P) 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    6월 여름맞이 특별전 해양수산부와 함께하는 수산대전20%

    ※기간 : 6/13(목) ~ 6/19(수) ※해당상품에 한함
    ※산지시황에 따라 조기품절될 수 있습니다 ※이미지 연출컷입니다
    ※L.POINT 회원 기간 중 1인 최대 1만원 할인 ※기간 內 주차별 1인/1만원 한도

    수산대전20%
    프리미엄 광어회 (500g 내외/팩/광어 : 국산) 29,984원

    수산대전20%
    ASC인증 완도 활전복 (대/14미/10마리) 19,840원

    수산대전20%
    원양산 오징어 (마리/해동) 2,384원

    수산대전20%
    영광 법성포 굴비 (1.4kg/팩/냉동/국산) 27,984원

    수산대전20%
    국산 고등어필렛 (1kg/팩/냉동) 8,784원

    수산대전20%
    황태채(400g/팩/러시아산)/볶음용, 조림용멸치(각 500g/팩/멸치:국산) 각9,984원

    제스프리 점보 골드키위(8~12입/팩/뉴질랜드산) 14,980원

    스윗탱고 토마토 (1.5kg/국산) 10,480원

    시즌컬러 채소모둠 (500g/팩/국내산) 4,680원

    양파(3kg/국내산) 5,380원
    ※기간 : 6/13(목)~6/19(수)

    새송이 버섯 (600g/팩/국내산) 2,780원

    양상추 (1봉/국내산) 1,980원

  </div>
  `,
  `
  <div>
    가바현미 7곡 (4.2kg/국내산) 2,000원 할인 14,980원

    귀한쌀(20kg/국내산) 7,000원 할인 49,800원

    하루한줌 고메넛츠 (30g×25봉/원산지 별도표기) 2,000원 할인 15,980원

    호두(1kg/미국산) 1,500원 할인 9,480원

    손질민물장어 (500g/팩/냉장/국내산) 34,980원

    데친 문어 (100g/냉장/베네수엘라산) 2,480원

    L.POINT 5,000원 할인
    생칵테일새우살 51-70 (908g/팩/냉동/베트남산) 14,980원

    L.POINT 3,000원 할인
    한판 모둠 소시지 (1.1kg/냉장/원산지 별도표기) 8,980원

    L.POINT 2,000원 할인
    요리하다 다리살로만 훈제오리(450g/냉장/원산지 별도표기) 8,980원

    L.POINT 각2,000원 할인
    하림 IFF 6개 품목 ※냉동/국내산 (상품별 상이/품목 2kg) 

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    L.POINT 15% 할인
    참한 새우초밥 (24P/팩/냉장/원산지 별도표기) 19,533원

    오징어볼/쉬림프칩 (240g/160g/원산지 별도표기) 2,000원 할인 각7,980원

    L.POINT 6,000원 할인
    100g당 1,290원
    양념 소불고기 (2kg/팩/냉장/원산지 별도표기) 25,800원

    서리태 콩국물/국산콩 콩국물(각 1L/국내산) 5,880/4,980원

    경양식 함박스테이크(9입/팩/원산지 별도표기) 13,980원

    일품 월남쌈 세트 (팩/원산지 별도표기) 13,980원

    가성비 식사/사이드 세트, 감탄박스 (1팩/원산지 별도표기) 8,900원

    오리지널 핫도그 (5입/1팩/원산지 별도표기) 2,000원 할인 8,900원

  </div>
  `,
  `
  <div>
    최대3,000원 할인 
    동원 고추참치(100g×10입)/동원 리챔 더블라이트(300g×6입) 15,480/21,980원
    
    2,740/4,000원 할인
    티아시아 골든 버터 난(200g)/쉐푸드 생크림버터 치킨카레,토마토 비프카레(각 170g×4입) 2,740/5,980원

    최대 40% 할인
    디벨라 파스타면 5종(상품별 규격 상이) 
    ※ 스파게티니, 스파게티, 링귀네, 푸실리, 펜네

    롯데카드/신한카드 4,000원 할인
    매일 상하 체다 슬라이스 치즈 (1.36kg/80매) 13,480원

    빙그레 딸기맛 우유 (240ml×8입) 1,000원 할인 7,480원

    5개 구매시 5,980원
    삼립 인기빵 10종(상품별 규격 상이) 5개 이상 구매시 1개당 각1,196원 
    ※주종발효 단팥크림빵, 주종발효 카스타드 소보루 外
    ※1개 구매시 각 1,480원

    트리코 고프레 프렌치 쿠키(450g) 14,980원

    트윅스 미니스 (1.02kg) 5,000원 할인 16,480원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    1+1 & 50% 할인

    1+1
    CJ 삼호 오뎅한그릇(360g)/구이한판 스모크 그릴(60g×4) 3,680/8,480원

    1+1
    사세 쏘스치킨 윙봉/순살 단짠갈릭맛(300g/375g) 각8,900원

    50%
    사조대림 국물떡볶이 (373g) 2,340원

    1+1
    풀무원 납작지짐만두(700g) 8,980원

    1+1
    동원 양반 수라 왕갈비탕/도가니설렁탕/보양추어탕/통다리삼계탕(각 460g) 각8,980원

    1+1
    담터 콤부차 샤인머스캣 포켓몬 기획(5g×30입) 15,980원

    1+1
    립톤 아이스티 피치(14g×120입) 37,980원
    ※ 점별 재고 한정

    1+1
    락티브 어린이 면역엔 멀티비타민 젤리(4g×60EA) 24,980원

  </div>
  `,
  `
  <div>
    크러시 쿨러백 (355ml×18입) 26,800원

    볼쓰 멜론×파쏘아 하이볼 패키지 (700ml×2ea+리유저블컵 3ea) 39,800원

    트레비 탄산수 3종 (각 300ml×20입) 각7,980원
    ※ 레몬/라임/플레인

    남양 프렌치카페 믹스 (10.9g×300입) 2,000원 할인 27,780원

    롯데카드/신한카드 5,000원 할인
    베지밀 검은콩과 검은참깨 두유 (190ml×24입) 11,980원

    멘토스 뉴레인보우 츄잉캔디 (미니/1kg) 1,800원 할인 9,480원

    뉴트리원 프리미엄 프리바이오틱스(5g×50포) 4,000원 할인 12,980원

    내추럴박스 카무트 브랜드 밀효소 (3g×15포×2입) L.POINT 2,000원 할인 19,980원

    롯데카드/신한카드 4,000원 할인
    바이탈슬림 리얼컷 아이돌물 녹차꿀맛 슬림(20ml×14포) 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    롯데카드/신한카드 3,000원 추가할인
    잘풀리는집 명품 로션티슈 에센셜(30m×30롤) 16,980원

    롯데카드/신한카드 2,000원 추가할인
    탑스텝 3in1 캡슐 세탁세제(100입) 15,980원

    옹달샘 습기제거제(18입) 2,000원 할인 12,980원

    롯데카드/신한카드 3,000원 할인
    시린메드 검케어(125g×5) 18,980원

    퍼펙트 건조대(대) 5,000원 할인 19,980원

    3M 이지 극세사 막대걸레 세트 (막대걸레 1P+극세사패드4매 구성) 6,000원 할인 18,980원

    제크롤 후면식 선풍기 (JK BF1400) 4,000원 할인 31,800원

    모리츠 메탈 20인치 선풍기 (MO 200MF) 4,000원 할인 55,800원

  </div>
  `,
  `
  <div>
    최대 70,000원 할인
    라코스테 클래식 카라/그래픽 반소매 티셔츠/블랙야크 웨크웨어 반소매 카라/라운드 티셔츠 24,980~59,800원
    
    나이키 브라질리아 더플백 (S, 41L) 3,000원 할인 32,800원

    NEW Arrival

    두툼한 손질 갈치 (400g/냉동/팩/모로코산) 6,880원

    MSC 짜먹는 냉동 명란 (30g×10팩/냉동/원산지 별도표기) 12,800원

    간편 고등어구이 (90g×4봉/냉동/원산지 별도표기) 13,800원

    뚜껑과 빨때를 제거해 환경까지 생각한 마이카페라떼 그린!
    매일카페라떼 마일드 컴포리드 (200ml×8입) 9,480원

    뉴트리 에버콜라겐 고백 (750mg×84정) 32,980원

    헤어플러스 아쿠아 워터 에센스 (200ml×2) 19,980원

    에이센트 3X 고농축 퍼퓸드 섬유유연제(4L) 15,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
];

/* 738 maxx(~2/26) */
const maxxNewData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
    ·전단적용기간 : 2025. 2. 13(목) ~ 2025. 2. 26(수) ·롯데마트맥스 : 금 ·전단적용기간 : 2025. 2. 13(목) ~ 2025. 2. 26(수) 천, 목포, 상무, 송천, 영등포, 창원중앙점
    봄맞이 제철 신선 페스타
    담양딸기(1kg/박스/국산) 롯데카드/신한카드 2,000원 할인 21,980원
    미국산 초이스 부채살 (100g/냉장/미국산) 롯데카드/신한카드 30% 할인 1,946원
    네이블 오렌지(9~15입/봉/미국산)/천혜향(2.3kg/봉/국산) 14,980/19,980원
    L.POINT 20% 할인
    캐나다산 돼지 삼겹살/목심 (각 100g/냉장/캐나다산) 각1,184원
    국산 조개 페스타 롯데/신한카드 결제시 30%
    ※명주조개/바지락/홍합/홍가리비/석화/동죽/꼬막
    ※산지 시황에 따라 조기 품절될 수 있습니다
    ※이미지 컷입니다
    L.POINT 20% 할인 새우&연어 드레싱 초밥 (20P/팩/원산지 별도표기) 15,184원
    L.POINT 35% 할인 산더미 콩나물 제육불고기 (1kg/냉장/원산지 별도표기) 12,870원
    스윗텔 스낵토마토 (1.5kg/팩/국산) 10,980원
    태안 냉이/달래(각 90g/팩/국산) 각1,980원
    친환경 어린잎 채소(90g×2/국산) 2,680원
    팽이버섯(720g/봉/국산)/친환경 만가닥버섯(400g/팩/국산) 2,180/2,380원
    동물복지 백색 유정란(30입/대란/국산) 2,000원 할인 7,980원
    하림 동물복지 닭볶음탕(1.3kg/냉장/국내산) L.POINT 1,000원 할인 8,980원
    2025 대한민국수산대전 해양수산부와 함께하는 수산대전 20%
    ※기간 : 2/20(목) ~ 2/26(수) ※해당상품에 한함 ※산지시황에 따라 조기품절될 수 있습니다
    ※L.POINT 회원 기간 내 주차별 1인 최대 1만원 할인 수산대전 20% ※이미지 연출컷입니다
    프리미엄 광어회(500g내외/팩/광어:국산)/횟감용 광어 필렛(100g/냉장/국산) 수산대전 20% 29,984/5,984원
    남해안 봉지굴(350g/봉/냉장/국산)/남해안 생굴(100g/냉장/국산) 수산대전 20% 5,984/1,424원
    국산 고등어 필렛(1kg/팩/냉동/국산) 수산대전 20% 9,584원
    원양산 오징어(마리/해동) 수산대전 20% 2,384원
    볶음용/볶음조림용 멸치 (각 500g/원산지 별도표기) 수산대전 20% 각9,984원
    황태채(400g/원산지 별도표기) 수산대전 20% 9,984원
    봄맞이 maxx 추천 아이템
    롯데카드/신한카드 5,000원 할인
    요즘 플레인 그릭요거트(450g) 9,980원
    2개 이상 50%
    마니커 리얼치킨 통살치킨너겟(1kg) 2개 이상 구매시 1개당 각6,490원
    ※1개 구매시 12,980원
    롯데 카스타드 스트로베리(204g)/몽쉘 딸기 생크림 케이크(408g) 4,280/4,680원
    ※시즌한정 상품으로 조기 품절될 수 있습니다
    아보케어 블렌드 프리미엄오일(2L) 5,000원 할인 24,980원
    롯데카드/신한카드 1+1
    테일러 유기농 하루 레몬즙(20ml×10입) 10,980원
    스피드펀치 욕실용 세정제(800ml×3) 3,000원 할인 10,980원
    바른세제연구소 베이킹소다/과탄산소다(6kg/3kg) 최대 1,500원 할인 13,480/9,980원
    스텐 2단 식기건조대 9,000원 할인 69,980원
    리빙몬스터 원데이 수세미(100입) 3,000원 할인 11,980원
    키친플라워 인덕션 국수냄비(18cm) 2,000원 할인 12,980원
    애견 꿀잠 범퍼 방석 (75×58×19cm) 19,980원
    나이키 에브리데이 에센셜 크루삭스/플러스 쿠션 앵클삭스 19,980/22,980원
    남성/여성 스트레이트 베이직 스판 데님/여성 부츠컷 스판 데님 각15,980원
    뉴발란스 바람막이 자켓 59,800원
    ※대표상품 이미지 컷입니다 ※점별 입고상품 상이 ※매장 내 다양한 상품이 준비되어 있습니다 
    블랙야크 워크웨어 긴팔 카라 티셔츠 39,800원
    롯데/신한 제휴카드 결제 고객 대상
    놀라운 혜택! 해당상품 20% 할인
    ▶ 기간 : 2/13(목) ~ 2/26(수) ▶ 해당카드 : 롯데마트&MA XX카드, LOCA for 롯데마트카드, 롯데마트맥스 신한카드
    딸기/바나나 전품목 20%
    ※ 점별 운영품목 상이 ※냉동과일 제외
    일상용품 전품목 20%
    ※ 점별 재고 상이

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    국산 그린키위(16~20입/팩) 14,980원
    블랙사파이어 포도 (900g/팩/호주산) 12,980원
    감자(1.5kg/봉/국산)/뉴질랜드산 단호박(1.2kg 내외/개) 4,280/3,980원
    양파(3kg/망/국산) 6,380원
    L.POINT 2개 이상 각 1,000원 할인
    바프 전품목(상품별 상이/원산지 별도표기) 2개 이상 구매시 1개당 각2,980원
    ※허니버터 아몬드 120g 外 ※교차구매 가능 ※1개 구매시 각3,980원
    시즈닝 캐슈넛(70g×3입) L.POINT 1,000원 할인 8,980원
    임금님표 이천쌀(10kg/국산)/카무트 혼합곡(4kg/원산지 별도표기) 최대4,000원 할인 39,800/11,480원
    아구포채/지도표 성경 재래식탁김 (250g/5g×30봉, 원산지 별도표기) L.POINT 최대 3,000원 할인 9,980/7,980원
    1등급 한우 차돌박이/갈비살 (700g/600g, 냉장, 국내산 한우고기) L.POINT 각 7,000원 할인 47,800/72,800원
    호주산 곡물비육 소 치마살/호주산 곡물 샤브용 부채살 (각 1kg/냉장/호주산) L.POINT 각 5,000원 할인 37,800/29,800원
    미국산 초이스 소살치살/꽃갈비살(각 100g/냉장/미국산) 4,980/7,980원
    캐나다산 돼지 목전지 (100g/냉장/캐나다산) 990원
    수원식 양념돼지 왕갈비구이 (1.7kg/냉장/원산지 별도표기) L.POINT 5,000원 할인 24,980원
    요리하다 다리살로만 훈제오리 (450g/냉장/원산지 별도표기) L.POINT 2,000원 할인 9,980원
    폭립(1kg/원산지 별도표기) 17,980원
    중화해물양장피(팩/원산지 별도표기) 15,980원
    크랜베리 크림치즈빵(3입/원산지별도표기) 1,200원 할인 4,790원
    ※ 롯데마트맥스 상무, 목포점에 한함
    톡톡 깨먹는 티라미수 (3입/1팩/원산지별도표기) 1,000원 할인 8,900원
    ※베이커리팩토리 입점점에 한함
    소와나무 체다, 고칼슘 슬라이스 치즈(270g×4입) 3,500원 할인 15,980원
    파스퇴르 쾌변 요구르트 (150ml×8입) 2,000원 할인 8,780원
    삼립 폭신폭신 플레인 치즈케익(70g×9입) 2,500원 할인 5,980원
    고래사 5가지 야채 듬뿍 사각어묵(600g) 2,000원 할인 4,480원
    목우촌 오븐 닭다리살 오리지널/숯불양념맛(각 600g) 3,000원 할인 각9,980원
    풀무원 우삼겹 김치볶음밥 5인 (1,250g) 5,000원 할인 9,980원
    롯데카드/신한카드
    디벨라 파스타면 5종(상품별 규격상이) 해당카드 결제시 40%
    ※스파게티니/스파게티/링귀네/푸실리/펜네
    2+1 청정원 파스타소스(상품별 규격 상이) 8,980/9,980원
    ※로제, 아라비아따, 토마토/까르보나라,투움바 ※동일 가격 상품에 한함
    MAXX 국내산 쌀누룽지(2kg) 3,500원 할인 20,480원
    1+1 동원 양반 진국 사골곰탕(500g) 1,980원
    농심 알쿠니아 황도(840g×3입) 3,250원 할인 12,980원
    크라운 초코하임(142g×5입) 롯데카드/신한카드 2,500원 할인 6,980원
    허니 한입 꽈배기(520g) 1,500원 할인 7,480원
    오리온 고래밥 볶음양념맛 (40g×15입) 1,000원 할인 6,980원
    삼진 그랩어바이트 바삭칩(240g) 2,500원 할인 5,480원
    롯데카드/신한카드 최대6,000원 할인
    칠성사이다/펩시콜라/펩시콜라 제로라임(각 210ml×30입) 각9,980원
    ※기간 : 2/13(목)~2/16(일) ※1인당 10개 구매 한정
    링티제로 레몬 라임맛 (1L×6입) 1,000원 할인 9,980원
    1+1 자임 하루하나 유기농 레몬즙/생강레몬즙(각 20×14입) 각15,800원
    ※교차구매 가능
    맥널티 스테비아 커피믹스 증정기획 (9.5g×20입) 5,000원 할인 20,980원
    칠성 밀키스 제로(300ml×10입) 3,000원 할인 6,480원
    뉴트리원 올인원 이뮨플러스(21.5g×14입) 5,000원 할인 19,980원
    하이뮨 프로틴 밸런스 음료(190ml×24입) 25,980원
    뉴트리원 온가족 종합비타민&미네랄 (1,500mg×365정) L.POINT 3,000원 할인 12,980원
    오리온 더 드래프트(500ml×8) 22,400원
    잘풀리는집 명품 로션티슈 에센셜 (30m×30롤) 5,500원 할인 롯데카드/신한카드 3,000원 추가할인 16,980원
    잘풀리는집 대용량 키친타월 (150매×12롤) 1,500원 할인 7,400원
    암앤해머 트로피컬 파라다이스(2.21L×2) 7,000원 할인 20,980원
    가그린 제로 (1,200ml+250ml) 2,000원 할인 7,980원
    2080 닥터 크리닉 미백치약(100g×4입) 1,500원 할인 8,980원
    끌레드벨 콜라겐 화이트닝 스킨(100ml×2입) 3,000원 할인 19,980원
    비프루브 제주 알로에 수딩 클렌징폼(150ml×3입) 1,000원 할인 9,980원
    양치하개 스케일링(24P) 1,500원 할인 11,980원
    나이키 퓨추라 미니 백팩 FQ5559 7,000원 할인 31,800원
    아디다스 오리지널 슬리퍼 5,820원 할인 29,980원
    로얄그래프톤 리썸 스텐 찜곰솥(30cm) 3,000원 할인 23,980원
    스텐 전기포트(1.8L) 1,000원 할인 8,980원

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    
  </div>  
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getVicData = () => {
  const dataArray = [];
  vicData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxData = () => {
  const dataArray = [];
  maxxData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getVicNewData = () => {
  const dataArray = [];
  vicNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxNewData = () => {
  const dataArray = [];
  maxxNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L705: {
    title: "스마트전단지-빅마켓705",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L755: {
    title: "스마트전단지-빅마켓755",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L718: {
    title: "스마트전단지-맥스718",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202502_1/07",
    contents: getMaxxData(),
  },
  L703: {
    title: "스마트전단지-빅마켓703",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L735: {
    title: "스마트전단지-빅마켓735",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L738: {
    title: "스마트전단지-맥스738",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202502_3/07",
    contents: getMaxxNewData(),
  },
};

export default data;
