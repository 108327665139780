import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2025. 2. 6(목) ~ 2025. 2. 12(수) < 비신선식품 적용기간 : 2025. 1. 31(금) ~ 2. 12(수) >
    롯데마트 맛 좋은 신선과 간편식 추천!
    명절 끝! 집밥 솔루션
    무항생제 한우 전품목(1+등급)(각 100g/냉장/국내산 한우고기) L.POINT 30%
    ※부위별 조기 품절될 수 있습니다
    제주 갈치 (특대/마리/해동/국산) L.POINT 40% 6,480원
    대왕님표 여주쌀 (8kg/국산) 34,900원
    제철 방어회(300g 내외/냉장/방어:국산) L.POINT 20% 24,800원
    전주식 비빔 반찬세트/모둠나물 비빔 반찬세트 (팩/원산지 별도표기) 각4,990원
    대추방울토마토/스위텔 토마토 (상품별 상이/국산) ※교차구매 가능 2팩 이상 구매시 각 1,000원 할인
    2개 이상 10% 청정원 호밍스 냉동 밀키트 8종(상품별 규격 상이) 2개 이상 구매시 1개당 각9,900~13,950원
    ※부산식 곱창전골 外 7종 ※교차구매 가능 ※1개 구매시 각 11,000~15,500원
    2개 이상 각1,980원 할인 
    풀무원 생 수제비/우동 5종 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 6,980원 
    3개 이상 각 1,490원 할인
    오뚜기 맛있는 떡볶이 3종 (상품별 상이) 3개 이상 구매시 1개당 각3,300원
    ※교차구매 가능 ※1개 구매시 각 4,790원
    1+1 동원 바른어묵 전골용 (350g) 4,480원
    최대 50%
    제로 대용량 탄산음료 15종 (상품별 행사 상이/1.2L~1.8L) 1,290~2,740원
    세계맥주 골라담기 26종 (상품별 용량 상이) 6캔 구매시 12,900원
    ※4캔 구매시 9,600원 ※1캔 구매시 3,000~3,300원
    정월 대보름 부럼깨고 소원빌자 
    미리 준비하는 알뜰한 2025년 정월대보름
    3봉 이상 각 1,000원 할인
    2봉 이상 각 500원 할인
    건나물 11종(상품별 용량 상이) ※교차구매 가능 ※ 건취나물/건호박/건곰취/건가지/건고구마순/건토란대/무말랭이/건더덕취/건곤드레/건고사리/건부지갱이 ※품목당 가격은 매장에서 확인하실 수 있습니다
    2개 이상 각 2,000원 할인
    오곡밥/부럼세트 (상품별 상이/원산지 별도표기) 2개 이상 구매시 1개당 각7,990/5,990원
    ※1개 구매시 각 9,990/7,990원
    ※교차구매 가능 
    왕 밤(700g/봉/국산) 7,990원
    대보름 삶은나물 6종 (400g/팩/국산) ※기간 : 2/8(토) ~ 2/12(수) 9,990원
    롯데마트GO 앱 특가!
    ▶기간 : 2/6(목) ~ 2/12(수) ※롯데마트GO앱 스캔시 행사가 적용됩니다
    GO앱 스캔시 2,000원 할인
    칠레산 블루베리 (310g/팩/칠레산) 5,990원
    GO앱 스캔시 40%
    온가족 모둠 초밥(30입) (팩/원산지 별도표기) 17,994원 
    GO앱 스캔시 50%
    봉지굴 전품목 (상품별 상이)
    GO앱 스캔시 1+1
    광천김 유아 김/김자반 7종 (상품별 규격 상이) 각3,780~7,680원
    ※동일가격 간 교차구매 가능
    2025 롯데마트 물가잡기 캠페인 The HOT 매주 꼭 필요한 상품을 The HOT하게 제안합니다
    이번주 핫프라이스 HOT
    1+1 마늘치킨 훈제 슬라이스 (400g/냉장/원산지 별도표기) 9,990원 ※기간 : 2/6(목) ~ 2/12(수)
    롯데/신한/NH농협/삼성카드 2개 이상 50%
    K-Street FOOD 인기 냉동분식/간식 먹거리 13종 (상품별 규격 상이) 해당카드로 2개 이상 구매시 1개당 해당카드로 2개 이상 구매시 1개당
    ※기간 : 2/6(목) ~ 2/12(수) ※교차구매 가능
    ※고래사, 미미네, 추억의 국민학교, 풀무원 ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 각 5,800~11,700원
    1,500원 할인 롯데/신한/NH농협/삼성카드 1+1
    매일 페레로 로쉐 콜렉션 T24(259.2g) 25,000원
    ※기간 : 2/6(목) ~ 2/14(금) ※점별 입고수량 상이 ※재고 소진시 조기 종료
    이달의 핫PB HOT
    요리하다 찰통순대 (500g) 500원 할인 2,990원
    요리하다 리얼새우볶음밥 (400g) 3,990원
    오늘좋은 꿀유자차/꿀레몬차(각 1kg) 500원 할인 각5,990원
    오늘좋은 살코기참치/고추참치(90g×4입) 2,000원 할인 각5,990

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    
  </div>
  `,
  `
  <div>
    캐나다산 척아이롤 (100g/냉장/캐나다산) L.POINT 40%
    국내산 돼지 불고기(앞/뒷다리) (각 100g/냉장/국내산) 1,390/1,190원
    ※제주점 제외 ※조기 품절될 수 있습니다
    국내산 대패 삼겹살/목심 (각 800g/냉동/국내산) 18,900/17,900원
    ※제주점 제외 ※조기 품절될 수 있습니다
    산더미 대파 소불고기 (800g/냉장/원산지 별도표기) L.POINT 50% 14,900원
    파타고니아 생연어 (250g/냉장/칠레산) L.POINT 4,000원 10,900원
    완도 활 전복(중)(10마리/냉장/국산) L.POINT 25% 18,600원
    제첩국 번들 기획팩 (500g×2/냉장/원산지 별도표기) L.POINT 30% 6,930원
    동원 양반 들기름향이 그윽한 김 (4.5g×20봉/원산지 별도표기) 7,980원
    꽃보다 오징어 슬라이스 (160g) L.POINT 2,000원 15,990원
    2개 이상 각500원 할인
    봄나물 모음전(상품별 상이) 2개 이상 구매시 1개당 각1,990~3,490원
    ※교차구매 가능 ※1개 구매시 각2,490~3,990원
    ※냉이/달래/봄동/유채나물/청도미나리 등
    영암 친환경 꿀고구마 (1.5kg/박스/국산) 6,990원
    뉴질랜드 단호박 (통/뉴질랜드산) 3,990원
    제주 한라봉/천혜향/레드향(각1.5kg/박스/국산) 15,990/17,990/19,990원
    이스라엘산 레드자몽 (5~8입/봉/이스라엘산) 7,990원
    삼천포에 빠진 골드원 키위 (8~12입/팩/국산) 9,990원
    NEW 한통 가아아득 치킨 (1팩/원산지 별도표기) ※제주점 제외 L.POINT 25% 9,743원
    온가족 한통 가득 탕수육 (1팩/원산지 별도표기) 12,990원
    한달내내 +50% 중량 UP "가격은 그대로
    온가족 모둠 도시락 (1팩/원산지 별도표기) 14,990원
    5무 훈제오리  (1팩/원산지 별도표기) 11,193원 L.POINT 30% 
    통족발(중)(1팩/원산지 별도표기) 13,900원
    ※족발 매장 입점점에 한함
    하나사면 하나 더 1+1
    1+1 동원 가쓰오 크랩스 (144g) 3,980원
    1+1 CJ 비비고 떡볶이 3종 (각 360g, 오리지널/매운/치즈) 각5,480원
    ※교차구매 가능
    1+1 종가 맛김치 (800g) 14,900원
    1+1 롯데쉐푸드 미니돈까스 (380g) 6,980원
    1+1 하림 더미식 고기군만두(320g×2)/부추 새우 손만두(350g×2) 10,880/13,980원
    ※기간 : 2/6(목)~2/9(일) ※동일 상품에 한함
    1+1 씨제이 햇반 곤약밥 2종 (각 150g×3입, 귀리흑미/현미귀리) 각8,980원
    ※교차구매 가능
    1+1 다담 파우치양념 5종 (상품별 규격 상이) 각2,980원
    ※교차구매 가능 
    1+1 해찬들 원조 태양초 고추장(1.5kg) 19,000원
    1+1 빙그레 끼리 크림치즈 그릭스타일(200g) 8,000원
    1+1 파워에이드 1.5L 外 스포츠음료 7종(상품별 상이) ※동일 브랜드간 교차구매가능 1,980~3,980원
    1+1 달고나 쿠키/허쉬 초콜릿칩쿠키/허쉬 초콜릿칩 베리쿠키 (각 144g) ※교차구매 가능 각5,290원
    L.POINT 1+1 삼립 상쾌한아침 우유식빵/삼립 미니 꿀호떡 2종 (상품별 규격 상이) ※오리지널/옥수수 ※교차구매 가능 각3,480원
    1+1 베지밀 검은콩 고칼슘두유 (파우치)(190ml×15입) 18,750원
    1+1 대용량 과채주스 27종(1L~1.8L) 3,630~14,900원
    ※동일 브랜드 내 동일가격 간 교차구매가능
    1+1 녹차원 꿀 유자차 外 5종 (각 1kg) 각9,900원
    ※교차구매 가능 
    1+1 녹차원 생강차/쌍화차 (각 15g×30입) 각9,900원
    ※교차구매 가능
    2개 이상 구매시 더 싸게
    2개 이상 각1,980원 할인 
    CJ 육공육 후랑크 2종 (상품별 상이) 2개 이상 구매시 1개당 각5,000원
    ※교차구매 가능 ※1개 구매시 각6,980원
    2개 이상 20%
    CJ 비비고 순살 구이 (삼치/고등어, 상품별 상이) 2개 이상 구매시 1개당 각4,192원
    ※1개 구매시 각5,240원 ※교차구매 가능
    2개 이상 25%
    하남돼지집 매콤 콩나물 불고기 外 3종(상품별 상이)  2개 이상 구매시 1개당 각7,425~11,175원
    ※1개 구매시 각9,900~14,900원 ※교차구매 가능
    2개 이상 50%
    풀무원 노엣지 피자 3종 (상품별 규격 상이) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능 ※코리안BBQ/포테이토&콘/토마토&미트콤보 ※1개 구매시 각9,980원
    2개 이상 각 1,000원 할인
    CJ 고메 소바바 치킨 5종 양념순살 外 4종, 상품별 규격 상이) ※교차구매 가능
    2개 이상 구매시 1개당 각7,980원 ※1개 구매시 각8,980원
    2개 이상 20%
    오뚜기 마열라면 (4개입) 2개 이상 구매시 1개당 각3,584원
    ※1개 구매시 4,480원
    2개 이상 40%
    씨제이 햇반 솥반 5종 (상품별 규격 상이) 2개 이상 구매시 1개당 각2,988원
    ※교차구매 가능 ※1개 구매시 각4,980원
    2개 이상 50%
    샘표 고추장/된장/쌈장 전품목 15종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,245~16,490원
    ※교차구매 가능 ※점별 운영상품 상이 ※1개 구매시 각2,490~32,980원
    2개 이상 50%
    남양 불가리스 제로 3종 (각 80g×4입) 2개 이상 구매시 1개당 각1,890원
    ※딸기/복숭아/플레인 ※1개 구매시 각3,780원 ※교차구매 가능
    2개 이상 50%
    일동후디스 그릭요거트 3종 (각 80g×4입) 2개 이상 구매시 1개당 각3,200원
    ※플레인/저지방/코코포도 ※1개 구매시 각6,400원 ※교차구매 가능
    2개 이상 10%
    크라운 스낵 5종 (상품별 상이) ※교차구매 가능
    2개 이상 구매시 1개당 각1,782원 ※1개 구매시 각1,980원

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
  `
  <div>
    발렌타인데이
    해당상품 롯데/신한/NH농협/삼성카드 1만 8천원 이상 결제시 5,000원 롯데상품권 증정
    ※해당 브랜드(8개사) : 롯데웰푸드/매일유업/농심/한국마즈/해태제과/크라운제과/오리온/삼경(하리보 제외)
    ※페이지 하단 카드할인 세부내용 참고 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " V "를 확인하세요 ※결제시 계산대에서 수령

    매일 페레로로쉐 T8 하트(100g)/T24 다이아(300g) 10% 8,820/22,590원
    롯데 프리미엄 가나 레몬 치즈 타르트/베리무스케익 (각 68g) 각3,490원
    하리보 스위트박스 (600g) ※상품권 증정 제외 9,980원
    1+1 네슬레 킷캣 딸기 기획 (79g) ※상품권 증정 제외 4,980원
    부샤드 나폴리탄 카라멜&씨솔트 초콜릿 틴 기획(180g) ※상품권 증정 제외 15,980원

    목우촌 프라임 소시지 2종 (상품별 상이) 각6,980원
    ※기간 : 2/1(토)~2/28(금)
    풀무원 얇은피 꽉찬속 김치/고기만두(각 800g) 각8,980원
    사조대림 실속김밥 세트(510g) 7,980원
    ※기간 : 2/1(토)~2/28(금)
    사세 치킨 가라아게(500g) 3,000원 할인 6,980원
    풀무원 체다모짜 핫도그(320g)/탱글뽀득 핫도그(400g) 2,500원 할인 각6,480원
    풀무원 식물성 지구식단 로스팅 정면 (4개입) L.POINT 45% 2,998원
    백설 고소함 가득 참기름 L.POINT 6,970원 (500ml) 7,980원
    서울 후레쉬밀크 기획(900ml×2입) 4,480원
    서울 비요뜨 4종(상품별 상이)
    ※링크/초코링/크런치볼/쿠키앤크림 각700원 할인 각2,180원
    아임비타 해당상품 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " ■ "를 확인하세요 ※결제시 계산대에서 수령
    2+1 아임비타 이뮨플러스 (23.5g×7입) 16,900원
    2개 이상 34%
    락토핏 골드/코어/슬림 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각10,494~17,754원
    ※1개 구매시 각15,900~26,900원
    남양 드빈치 자연방목 피자치즈(1kg) 13,980원
    덴마크 드링킹 요구르트 5종(각 750ml) 각3,880원
    ※베리믹스/복숭아/샤인머스켓/플레인/딸기
    아침에주스 오렌지/포도/사과 (각 1.8L) 각600원 할인 각5,980원
    인기 생필품 득템 찬스
    1+1 히말라야 핑크솔트 바디워시 3종(각 900g) 각12,980원
    ※민트/로즈/포레스트 ※점별 운영상품 상이 ※교차구매 가능
    2개 이상 50%
    엘라스틴 아미노 프로틴 클리닉 샴푸/컨디셔너 (각 855ml) 2개 이상 구매시 1개당 각6,450원
    ※샴푸 : 손상/볼륨 ※컨디셔너 : 손상 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각12,900원
    2개 이상 각 2,500원 할인
    닥터그루트 집중케어 샴푸 기획 (각 샴푸 400ml+50ml+컨디셔너 50ml) 2개 이상 구매시 1개당 각20,500원
    ※힘없는 모발용/가려운 두피용 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각23,000원
    2개 이상 50%
    갸스비 왁스 4종&스프레이 3종 (상품별 상이) 2개 이상 구매시 1개당 각4,545~6,000원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각9,090~12,000원
    카밀 핸드크림 미니기획 (30ml×3입) ※점별 재고 상이 6,900원
    우르오스 스칼프 샴푸(500ml)/스킨 밀크, 스킨로션(각 200ml) 각23,500원
    ※점별 운영상품 상이
    2개 이상 50%
    테크/피지 액체세제 11종(상품별 상이) 2개 이상 구매시 1개당 각7,100~14,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각14,200~29,900원
    2개 이상 50%
    프릴 애플 주방세제 (용기 700ml/리필 1L) ※교차구매 가능 2개 이상 구매시 1개당 각4,750원
    ※1개 구매시 각9,500원
    2개 이상 50%
    홈스타 맥스프레쉬 4종(각 900ml) ※교차구매 가능 2개 이상 구매시 1개당 각3,950원
    ※1개 구매시 각7,900원
    남성 인기 화장품 최대 30% => 10% 추가할인
    ※기간 : 2/6(목)~2/12(수)
    ※참여 브랜드(7개사) : 엘지생활건강/아모레퍼시픽/이니스프리/토니모리/미샤/더샘/엔프라니
    ※입점점에 한함 ※브랜드별 할인율 상이
    쏘피 생리대 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령
    2개 이상 50%
    쏘피 한결 생리대(팬티라이너/중형, 대형, 슈퍼롱) 2개 이상 구매시 1개당 각4,250/5,950원
    ※교차구매 가능 ※1개 구매시 각8,500/11,900원
    최대 50%
    헨켈 라벤더 해당상품
    2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉭ "를 확인하세요
    ※결제시 계산대에서 수령
    헨켈(퍼실/버넬/브레프)라벤더 대전 (상품별 상이) 각2,250~19,900원
    하기스 기저귀 전품목 10만원 이상 구매시 10,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉻ "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 35%
    하기스 기저귀(상품별 상이) 2개 이상 구매시 1개당 각19,890~29,840원
    ※교차구매 가능 ※1개 구매시 각30,600~45,900원
    피죤 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉬ "를 확인하세요
    ※결제시 계산대에서 수령
    2개 이상 50%
    피죤(액츠/피죤/무균무때)브랜드 대전 (상품별 상이) 2개 이상 구매시 1개당 각3,450~9,950원 
    ※1개 구매시 각6,900~19,900원 ※브랜드별 교차구매 가능

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    인기 와인/위스키 해당상품 L.POINT 최대 30%
    L.POINT 30% 보시오 모스카토 다스티 루카/포르투 발도우로 토니 포트 각17,430원
    L.POINT 4,000원 피치트리(700ml) 22,900원
    L.POINT 7,400원 엠페라도르 디럭스 스페셜 리저브(700ml) 9,900원
    6캔 구매시 10%
    국민맥주 3종(각 500ml, 라거/에일/바이젠) 6캔 구매시 1캔당 각1,620~1,800원 
    ※1캔 구매시 각1,800~2,000원
    L.POINT 40% 
    해피콜 뉴골든구르메 IH 프라이팬/냄비(상품별 상이) 20,940~43,140원
    테팔/해피콜/도루코 인덕션 프라이팬 3종(각 28cm) 각20,900원
    ※기간 : 1/31(금)~2/26(수) ※테팔 리미티드/해피콜 루시드/도루코 웜그레이 프라이팬
    레녹스/프라카 식기(상품별 상이) 최대 40% 각4,830~21,540원
    1+1 기획팩 유니랩 위생롤백/장갑/지퍼백 (상품별 상이) 각3,480~5,980원
    1+1 기획팩 3M 황토숯 고무장갑/다목적 수세미(소 3입) 각3,190~4,380원
    썬 부탄가스(4입) 20% 5,430원
    2개 이상 50%
    네오플램 퓨어락(상품별 상이) 2개 이상 구매시 1개당 각2,200~6,200원 
    ※1개 구매시 각4,400~12,400원 ※교차구매 가능
    오늘좋은 냉동밥 용기 (8P) 4,900원
    오늘좋은 하트쿠션&과자 바디필로우 (상품별 상이) 20% 7,920~23,920원 
    ※점별 재고 상이 ※해당상품에 한함 ※재고 소진시 조기 종료
    오늘좋은 에어블 이불/바람솔솔 경추베개(상품별 상이) 20% 12,720~47,920원
    ※이불 - S : 150×200cm/Q : 180×220cm ※베개 - 30×50cm ※입점점에 한함 ※해당상품에 한함
    오늘좋은 아로마 세면타월(각 4입) 30% 각13,930원
    ※아쿠아/블라썸/유칼립투스/라벤더
    ※입점점에 한함 ※해당상품에 한함
    리필 추가증정
    3M 크린스틱 변기솔 리필 6입/베이킹소다 크린스틱 청소용 수세미 (상품별 상이) 5,690/10,880원
    ※입점점에 한함 ※해당상품에 한함
    오늘좋은 포켓형 장바구니 2종 20% 각2,000원
    자동차용품 클리어런스(상품별 상이) 최대 30% 각1,840~66,320원
    시들지 않는 프리저브드 안개꽃다발 (핑크/퍼플) 각19,900원
    ※손잡이 쇼핑백 포함 상품입니다
    2개 이상 50%
    하우홈 보온/단열시트 9종 ※교차구매 가능 2개 이상 구매시 1개당 각990~8,450원
    ※1개 구매시 각1,990~16,900원
    월드컵 에반/네로 운동화(상품별 상이) 각23,900/32,900원
    ※사이즈 : 230~280mm ※입점점에 한함
    브랜드 양말(상품별 상이) 2개 이상 30% 2개 이상 구매시 1개당 각7,920~8,720원
    ※1개 구매시 각6,930~7,630원
    ※교차구매 가능 ※뉴발란스, 언더아머, 휠라, 네파, MLB 등 ※점별 상품 상이
    오늘좋은 겨울 침구/담요/거실화 外 (상품별 상이) 각3,000~35,000원 
    ※입점점에 한함 ※해당상품에 한함 ※최초 판매가 기준 ※소진시 종료 ※점별 재고 상이 ※매장에 더많은 상품이 준비되어 있습니다
    오늘좋은 히트 언더웨어(1입) 각5,900~9,900원
    오늘좋은 수면/밍크스판 홈웨어 (바지/원피스/세트) 각6,900~12,900원
    산리오 外 아동 캐릭터 실내복(상품별 상이) 각6,900~21,900원
    ToysRus LEGO 이색적인 발렌타인 데이 선물 추천!
    ※기간 : 2/6(목)~2/26(수) ※입점점에 한함
    L.POINT 30%
    레고 장미(40460) (8세 이상/19.1×4.6×14.1cm) 레고 장미 꽃다발(10328) (18세 이상/26.2×9.1×38.2cm) 14,630/62,930원
    레고 핑크 꽃다발(10342) (18세 이상/26.2×7.1×38.2cm) 79,900원
    colioli 반려동물 식품 최대 30%
    시저 강아지 건사료/습식사료 23종 (상품별 상이) 최대 15% 각2,100~8,330원
    파티믹스 고양이 간식 7종 (각 60g) 30% 각3,010원
    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2025. 2. 6(목) ~ 2025. 2. 12(수) < 비신선식품 적용기간 : 2025. 1. 31(금) ~ 2. 12(수) >
    ·제타플렉스 잠실점
    롯데마트 제타플렉스 맛좋은 신선과 간편식 추천!
    명절 끝! 집밥 솔루션
    무항생제 한우 전품목(1+등급)(각 100g/냉장/국내산 한우고기) L.POINT 30%
    ※부위별 조기 품절될 수 있습니다
    제주 갈치 (특대/마리/해동/국산) L.POINT 40% 6,480원
    대왕님표 여주쌀 (8kg/국산) 34,900원
    제철 방어회(300g 내외/냉장/방어:국산) L.POINT 20% 24,800원
    전주식 비빔 반찬세트/모둠나물 비빔 반찬세트 (팩/원산지 별도표기) 각4,990원
    대추방울토마토/스위텔 토마토 (상품별 상이/국산) ※교차구매 가능 2팩 이상 구매시 각 1,000원 할인
    2개 이상 10% 청정원 호밍스 냉동 밀키트 8종(상품별 규격 상이) 2개 이상 구매시 1개당 각9,900~13,950원
    ※부산식 곱창전골 外 7종 ※교차구매 가능 ※1개 구매시 각 11,000~15,500원
    2개 이상 각1,980원 할인 
    풀무원 생 수제비/우동 5종 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 6,980원 
    3개 이상 각 1,490원 할인
    오뚜기 맛있는 떡볶이 3종 (상품별 상이) 3개 이상 구매시 1개당 각3,300원
    ※교차구매 가능 ※1개 구매시 각 4,790원
    1+1 동원 바른어묵 전골용 (350g) 4,480원
    최대 50%
    제로 대용량 탄산음료 15종 (상품별 행사 상이/1.2L~1.8L) 1,290~2,740원
    세계맥주 골라담기 26종 (상품별 용량 상이) 6캔 구매시 12,900원
    ※4캔 구매시 9,600원 ※1캔 구매시 3,000~3,300원
    정월 대보름 부럼깨고 소원빌자 
    미리 준비하는 알뜰한 2025년 정월대보름
    3봉 이상 각 1,000원 할인
    2봉 이상 각 500원 할인
    건나물 11종(상품별 용량 상이) ※교차구매 가능 ※ 건취나물/건호박/건곰취/건가지/건고구마순/건토란대/무말랭이/건더덕취/건곤드레/건고사리/건부지갱이 ※품목당 가격은 매장에서 확인하실 수 있습니다
    2개 이상 각 2,000원 할인
    오곡밥/부럼세트 (상품별 상이/원산지 별도표기) 2개 이상 구매시 1개당 각7,990/5,990원
    ※1개 구매시 각 9,990/7,990원
    ※교차구매 가능 
    왕 밤(700g/봉/국산) 7,990원
    대보름 삶은나물 6종 (400g/팩/국산) ※기간 : 2/8(토) ~ 2/12(수) 9,990원
    롯데마트GO 앱 특가!
    ▶기간 : 2/6(목) ~ 2/12(수) ※롯데마트GO앱 스캔시 행사가 적용됩니다
    GO앱 스캔시 2,000원 할인
    칠레산 블루베리 (310g/팩/칠레산) 5,990원
    GO앱 스캔시 40%
    온가족 모둠 초밥(30입) (팩/원산지 별도표기) 17,994원 
    GO앱 스캔시 50%
    봉지굴 전품목 (상품별 상이)
    GO앱 스캔시 1+1
    광천김 유아 김/김자반 7종 (상품별 규격 상이) 각3,780~7,680원
    ※동일가격 간 교차구매 가능
    2025 롯데마트 물가잡기 캠페인 The HOT 매주 꼭 필요한 상품을 The HOT하게 제안합니다
    이번주 핫프라이스 HOT
    1+1 마늘치킨 훈제 슬라이스 (400g/냉장/원산지 별도표기) 9,990원 ※기간 : 2/6(목) ~ 2/12(수)
    롯데/신한/NH농협/삼성카드 2개 이상 50%
    K-Street FOOD 인기 냉동분식/간식 먹거리 13종 (상품별 규격 상이) 해당카드로 2개 이상 구매시 1개당 해당카드로 2개 이상 구매시 1개당
    ※기간 : 2/6(목) ~ 2/12(수) ※교차구매 가능
    ※고래사, 미미네, 추억의 국민학교, 풀무원 ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 각 5,800~11,700원
    1,500원 할인 롯데/신한/NH농협/삼성카드 1+1
    매일 페레로 로쉐 콜렉션 T24(259.2g) 25,000원
    ※기간 : 2/6(목) ~ 2/14(금) ※점별 입고수량 상이 ※재고 소진시 조기 종료
    이달의 핫PB HOT
    요리하다 찰통순대 (500g) 500원 할인 2,990원
    요리하다 리얼새우볶음밥 (400g) 3,990원
    오늘좋은 꿀유자차/꿀레몬차(각 1kg) 500원 할인 각5,990원
    오늘좋은 살코기참치/고추참치(90g×4입) 2,000원 할인 각5,990

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    캐나다산 척아이롤 (100g/냉장/캐나다산) L.POINT 40%
    국내산 돼지 불고기(앞/뒷다리) (각 100g/냉장/국내산) 1,390/1,190원
    ※제주점 제외 ※조기 품절될 수 있습니다
    국내산 대패 삼겹살/목심 (각 800g/냉동/국내산) 18,900/17,900원
    ※제주점 제외 ※조기 품절될 수 있습니다
    산더미 대파 소불고기 (800g/냉장/원산지 별도표기) L.POINT 50% 14,900원
    파타고니아 생연어 (250g/냉장/칠레산) L.POINT 4,000원 10,900원
    완도 활 전복(중)(10마리/냉장/국산) L.POINT 25% 18,600원
    제첩국 번들 기획팩 (500g×2/냉장/원산지 별도표기) L.POINT 30% 6,930원
    동원 양반 들기름향이 그윽한 김 (4.5g×20봉/원산지 별도표기) 7,980원
    꽃보다 오징어 슬라이스 (160g) L.POINT 2,000원 15,990원
    2개 이상 각500원 할인
    봄나물 모음전(상품별 상이) 2개 이상 구매시 1개당 각1,990~3,490원
    ※교차구매 가능 ※1개 구매시 각2,490~3,990원
    ※냉이/달래/봄동/유채나물/청도미나리 등
    영암 친환경 꿀고구마 (1.5kg/박스/국산) 6,990원
    뉴질랜드 단호박 (통/뉴질랜드산) 3,990원
    제주 한라봉/천혜향/레드향(각1.5kg/박스/국산) 15,990/17,990/19,990원
    이스라엘산 레드자몽 (5~8입/봉/이스라엘산) 7,990원
    삼천포에 빠진 골드원 키위 (8~12입/팩/국산) 9,990원
    NEW 한통 가아아득 치킨 (1팩/원산지 별도표기) ※제주점 제외 L.POINT 25% 9,743원
    온가족 한통 가득 탕수육 (1팩/원산지 별도표기) 12,990원
    한달내내 +50% 중량 UP "가격은 그대로
    온가족 모둠 도시락 (1팩/원산지 별도표기) 14,990원
    5무 훈제오리  (1팩/원산지 별도표기) 11,193원 L.POINT 30% 
    통족발(중)(1팩/원산지 별도표기) 13,900원
    ※족발 매장 입점점에 한함
    하나사면 하나 더 1+1
    1+1 동원 가쓰오 크랩스 (144g) 3,980원
    1+1 CJ 비비고 떡볶이 3종 (각 360g, 오리지널/매운/치즈) 각5,480원
    ※교차구매 가능
    1+1 종가 맛김치 (800g) 14,900원
    1+1 롯데쉐푸드 미니돈까스 (380g) 6,980원
    1+1 하림 더미식 고기군만두(320g×2)/부추 새우 손만두(350g×2) 10,880/13,980원
    ※기간 : 2/6(목)~2/9(일) ※동일 상품에 한함
    1+1 씨제이 햇반 곤약밥 2종 (각 150g×3입, 귀리흑미/현미귀리) 각8,980원
    ※교차구매 가능
    1+1 다담 파우치양념 5종 (상품별 규격 상이) 각2,980원
    ※교차구매 가능 
    1+1 해찬들 원조 태양초 고추장(1.5kg) 19,000원
    1+1 빙그레 끼리 크림치즈 그릭스타일(200g) 8,000원
    1+1 파워에이드 1.5L 外 스포츠음료 7종(상품별 상이) ※동일 브랜드간 교차구매가능 1,980~3,980원
    1+1 달고나 쿠키/허쉬 초콜릿칩쿠키/허쉬 초콜릿칩 베리쿠키 (각 144g) ※교차구매 가능 각5,290원
    L.POINT 1+1 삼립 상쾌한아침 우유식빵/삼립 미니 꿀호떡 2종 (상품별 규격 상이) ※오리지널/옥수수 ※교차구매 가능 각3,480원
    1+1 베지밀 검은콩 고칼슘두유 (파우치)(190ml×15입) 18,750원
    1+1 대용량 과채주스 27종(1L~1.8L) 3,630~14,900원
    ※동일 브랜드 내 동일가격 간 교차구매가능
    1+1 녹차원 꿀 유자차 外 5종 (각 1kg) 각9,900원
    ※교차구매 가능 
    1+1 녹차원 생강차/쌍화차 (각 15g×30입) 각9,900원
    ※교차구매 가능
    2개 이상 구매시 더 싸게
    2개 이상 각1,980원 할인 
    CJ 육공육 후랑크 2종 (상품별 상이) 2개 이상 구매시 1개당 각5,000원
    ※교차구매 가능 ※1개 구매시 각6,980원
    2개 이상 20%
    CJ 비비고 순살 구이 (삼치/고등어, 상품별 상이) 2개 이상 구매시 1개당 각4,192원
    ※1개 구매시 각5,240원 ※교차구매 가능
    2개 이상 25%
    하남돼지집 매콤 콩나물 불고기 外 3종(상품별 상이)  2개 이상 구매시 1개당 각7,425~11,175원
    ※1개 구매시 각9,900~14,900원 ※교차구매 가능
    2개 이상 50%
    풀무원 노엣지 피자 3종 (상품별 규격 상이) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능 ※코리안BBQ/포테이토&콘/토마토&미트콤보 ※1개 구매시 각9,980원
    2개 이상 각 1,000원 할인
    CJ 고메 소바바 치킨 5종 양념순살 外 4종, 상품별 규격 상이) ※교차구매 가능
    2개 이상 구매시 1개당 각7,980원 ※1개 구매시 각8,980원
    2개 이상 20%
    오뚜기 마열라면 (4개입) 2개 이상 구매시 1개당 각3,584원
    ※1개 구매시 4,480원
    2개 이상 40%
    씨제이 햇반 솥반 5종 (상품별 규격 상이) 2개 이상 구매시 1개당 각2,988원
    ※교차구매 가능 ※1개 구매시 각4,980원
    2개 이상 50%
    샘표 고추장/된장/쌈장 전품목 15종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,245~16,490원
    ※교차구매 가능 ※점별 운영상품 상이 ※1개 구매시 각2,490~32,980원
    2개 이상 50%
    남양 불가리스 제로 3종 (각 80g×4입) 2개 이상 구매시 1개당 각1,890원
    ※딸기/복숭아/플레인 ※1개 구매시 각3,780원 ※교차구매 가능
    2개 이상 50%
    일동후디스 그릭요거트 3종 (각 80g×4입) 2개 이상 구매시 1개당 각3,200원
    ※플레인/저지방/코코포도 ※1개 구매시 각6,400원 ※교차구매 가능
    2개 이상 10%
    크라운 스낵 5종 (상품별 상이) ※교차구매 가능
    2개 이상 구매시 1개당 각1,782원 ※1개 구매시 각1,980원

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    발렌타인데이
    해당상품 롯데/신한/NH농협/삼성카드 1만 8천원 이상 결제시 5,000원 롯데상품권 증정
    ※해당 브랜드(8개사) : 롯데웰푸드/매일유업/농심/한국마즈/해태제과/크라운제과/오리온/삼경(하리보 제외)
    ※페이지 하단 카드할인 세부내용 참고 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " V "를 확인하세요 ※결제시 계산대에서 수령

    매일 페레로로쉐 T8 하트(100g)/T24 다이아(300g) 10% 8,820/22,590원
    롯데 프리미엄 가나 레몬 치즈 타르트/베리무스케익 (각 68g) 각3,490원
    하리보 스위트박스 (600g) ※상품권 증정 제외 9,980원
    1+1 네슬레 킷캣 딸기 기획 (79g) ※상품권 증정 제외 4,980원
    부샤드 나폴리탄 카라멜&씨솔트 초콜릿 틴 기획(180g) ※상품권 증정 제외 15,980원

    목우촌 프라임 소시지 2종 (상품별 상이) 각6,980원
    ※기간 : 2/1(토)~2/28(금)
    풀무원 얇은피 꽉찬속 김치/고기만두(각 800g) 각8,980원
    사조대림 실속김밥 세트(510g) 7,980원
    ※기간 : 2/1(토)~2/28(금)
    사세 치킨 가라아게(500g) 3,000원 할인 6,980원
    풀무원 체다모짜 핫도그(320g)/탱글뽀득 핫도그(400g) 2,500원 할인 각6,480원
    풀무원 식물성 지구식단 로스팅 정면 (4개입) L.POINT 45% 2,998원
    백설 고소함 가득 참기름 L.POINT 6,970원 (500ml) 7,980원
    서울 후레쉬밀크 기획(900ml×2입) 4,480원
    서울 비요뜨 4종(상품별 상이)
    ※링크/초코링/크런치볼/쿠키앤크림 각700원 할인 각2,180원
    아임비타 해당상품 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " ■ "를 확인하세요 ※결제시 계산대에서 수령
    2+1 아임비타 이뮨플러스 (23.5g×7입) 16,900원
    2개 이상 34%
    락토핏 골드/코어/슬림 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각10,494~17,754원
    ※1개 구매시 각15,900~26,900원
    남양 드빈치 자연방목 피자치즈(1kg) 13,980원
    덴마크 드링킹 요구르트 5종(각 750ml) 각3,880원
    ※베리믹스/복숭아/샤인머스켓/플레인/딸기
    아침에주스 오렌지/포도/사과 (각 1.8L) 각600원 할인 각5,980원
    인기 생필품 득템 찬스
    1+1 히말라야 핑크솔트 바디워시 3종(각 900g) 각12,980원
    ※민트/로즈/포레스트 ※점별 운영상품 상이 ※교차구매 가능
    2개 이상 50%
    엘라스틴 아미노 프로틴 클리닉 샴푸/컨디셔너 (각 855ml) 2개 이상 구매시 1개당 각6,450원
    ※샴푸 : 손상/볼륨 ※컨디셔너 : 손상 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각12,900원
    2개 이상 각 2,500원 할인
    닥터그루트 집중케어 샴푸 기획 (각 샴푸 400ml+50ml+컨디셔너 50ml) 2개 이상 구매시 1개당 각20,500원
    ※힘없는 모발용/가려운 두피용 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각23,000원
    2개 이상 50%
    갸스비 왁스 4종&스프레이 3종 (상품별 상이) 2개 이상 구매시 1개당 각4,545~6,000원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각9,090~12,000원
    카밀 핸드크림 미니기획 (30ml×3입) ※점별 재고 상이 6,900원
    우르오스 스칼프 샴푸(500ml)/스킨 밀크, 스킨로션(각 200ml) 각23,500원
    ※점별 운영상품 상이
    2개 이상 50%
    테크/피지 액체세제 11종(상품별 상이) 2개 이상 구매시 1개당 각7,100~14,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각14,200~29,900원
    2개 이상 50%
    프릴 애플 주방세제 (용기 700ml/리필 1L) ※교차구매 가능 2개 이상 구매시 1개당 각4,750원
    ※1개 구매시 각9,500원
    2개 이상 50%
    홈스타 맥스프레쉬 4종(각 900ml) ※교차구매 가능 2개 이상 구매시 1개당 각3,950원
    ※1개 구매시 각7,900원
    남성 인기 화장품 최대 30% => 10% 추가할인
    ※기간 : 2/6(목)~2/12(수)
    ※참여 브랜드(7개사) : 엘지생활건강/아모레퍼시픽/이니스프리/토니모리/미샤/더샘/엔프라니
    ※입점점에 한함 ※브랜드별 할인율 상이
    쏘피 생리대 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령
    2개 이상 50%
    쏘피 한결 생리대(팬티라이너/중형, 대형, 슈퍼롱) 2개 이상 구매시 1개당 각4,250/5,950원
    ※교차구매 가능 ※1개 구매시 각8,500/11,900원
    최대 50%
    헨켈 라벤더 해당상품
    2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉭ "를 확인하세요
    ※결제시 계산대에서 수령
    헨켈(퍼실/버넬/브레프)라벤더 대전 (상품별 상이) 각2,250~19,900원
    하기스 기저귀 전품목 10만원 이상 구매시 10,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉻ "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 35%
    하기스 기저귀(상품별 상이) 2개 이상 구매시 1개당 각19,890~29,840원
    ※교차구매 가능 ※1개 구매시 각30,600~45,900원
    피죤 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉬ "를 확인하세요
    ※결제시 계산대에서 수령
    2개 이상 50%
    피죤(액츠/피죤/무균무때)브랜드 대전 (상품별 상이) 2개 이상 구매시 1개당 각3,450~9,950원 
    ※1개 구매시 각6,900~19,900원 ※브랜드별 교차구매 가능

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    L.POINT 40% 
    해피콜 뉴골든구르메 IH 프라이팬/냄비(상품별 상이) 20,940~43,140원
    테팔/해피콜/도루코 인덕션 프라이팬 3종(각 28cm) 각20,900원
    ※기간 : 1/31(금)~2/26(수) ※테팔 리미티드/해피콜 루시드/도루코 웜그레이 프라이팬
    레녹스/프라카 식기(상품별 상이) 최대 40% 각4,830~21,540원
    1+1 기획팩 유니랩 위생롤백/장갑/지퍼백 (상품별 상이) 각3,480~5,980원
    1+1 기획팩 3M 황토숯 고무장갑/다목적 수세미(소 3입) 각3,190~4,380원
    썬 부탄가스(4입) 20% 5,430원
    2개 이상 50%
    네오플램 퓨어락(상품별 상이) 2개 이상 구매시 1개당 각2,200~6,200원 
    ※1개 구매시 각4,400~12,400원 ※교차구매 가능
    오늘좋은 냉동밥 용기 (8P) 4,900원
    오늘좋은 하트쿠션&과자 바디필로우 (상품별 상이) 20% 7,920~23,920원 
    ※점별 재고 상이 ※해당상품에 한함 ※재고 소진시 조기 종료
    오늘좋은 에어블 이불/바람솔솔 경추베개(상품별 상이) 20% 12,720~47,920원
    ※이불 - S : 150×200cm/Q : 180×220cm ※베개 - 30×50cm ※입점점에 한함 ※해당상품에 한함
    오늘좋은 아로마 세면타월(각 4입) 30% 각13,930원
    ※아쿠아/블라썸/유칼립투스/라벤더
    ※입점점에 한함 ※해당상품에 한함
    리필 추가증정
    3M 크린스틱 변기솔 리필 6입/베이킹소다 크린스틱 청소용 수세미 (상품별 상이) 5,690/10,880원
    ※입점점에 한함 ※해당상품에 한함
    오늘좋은 포켓형 장바구니 2종 20% 각2,000원
    자동차용품 클리어런스(상품별 상이) 최대 30% 각1,840~66,320원
    시들지 않는 프리저브드 안개꽃다발 (핑크/퍼플) 각19,900원
    ※손잡이 쇼핑백 포함 상품입니다
    2개 이상 50%
    하우홈 보온/단열시트 9종 ※교차구매 가능 2개 이상 구매시 1개당 각990~8,450원
    ※1개 구매시 각1,990~16,900원
    월드컵 에반/네로 운동화(상품별 상이) 각23,900/32,900원
    ※사이즈 : 230~280mm ※입점점에 한함
    브랜드 양말(상품별 상이) 2개 이상 30% 2개 이상 구매시 1개당 각7,920~8,720원
    ※1개 구매시 각6,930~7,630원
    ※교차구매 가능 ※뉴발란스, 언더아머, 휠라, 네파, MLB 등 ※점별 상품 상이
    오늘좋은 겨울 침구/담요/거실화 外 (상품별 상이) 각3,000~35,000원 
    ※입점점에 한함 ※해당상품에 한함 ※최초 판매가 기준 ※소진시 종료 ※점별 재고 상이 ※매장에 더많은 상품이 준비되어 있습니다
    오늘좋은 히트 언더웨어(1입) 각5,900~9,900원
    오늘좋은 수면/밍크스판 홈웨어 (바지/원피스/세트) 각6,900~12,900원
    산리오 外 아동 캐릭터 실내복(상품별 상이) 각6,900~21,900원
    ToysRus LEGO 이색적인 발렌타인 데이 선물 추천!
    ※기간 : 2/6(목)~2/26(수) ※입점점에 한함
    L.POINT 30%
    레고 장미(40460) (8세 이상/19.1×4.6×14.1cm) 레고 장미 꽃다발(10328) (18세 이상/26.2×9.1×38.2cm) 14,630/62,930원
    레고 핑크 꽃다발(10342) (18세 이상/26.2×7.1×38.2cm) 79,900원
    colioli 반려동물 식품 최대 30%
    시저 강아지 건사료/습식사료 23종 (상품별 상이) 최대 15% 각2,100~8,330원
    파티믹스 고양이 간식 7종 (각 60g) 30% 각3,010원

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaSeoulData = [
  `<h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2025. 2. 6(목) ~ 2025. 2. 12(수) < 비신선식품 적용기간 : 2025. 1. 31(금) ~ 2. 12(수) >
    ·제타플렉스 서울역점
    롯데마트 제타플렉스 서울역점 맛 좋은 신선과 간편식 추천!
    명절 끝! 집밥 솔루션
    무항생제 한우 전품목(1+등급)(각 100g/냉장/국내산 한우고기) L.POINT 30%
    ※부위별 조기 품절될 수 있습니다
    제주 갈치 (특대/마리/해동/국산) L.POINT 40% 6,480원
    대왕님표 여주쌀 (8kg/국산) 34,900원
    제철 방어회(300g 내외/냉장/방어:국산) L.POINT 20% 24,800원
    전주식 비빔 반찬세트/모둠나물 비빔 반찬세트 (팩/원산지 별도표기) 각4,990원
    대추방울토마토/스위텔 토마토 (상품별 상이/국산) ※교차구매 가능 2팩 이상 구매시 각 1,000원 할인
    2개 이상 10% 청정원 호밍스 냉동 밀키트 8종(상품별 규격 상이) 2개 이상 구매시 1개당 각9,900~13,950원
    ※부산식 곱창전골 外 7종 ※교차구매 가능 ※1개 구매시 각 11,000~15,500원
    2개 이상 각1,980원 할인 
    풀무원 생 수제비/우동 5종 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 6,980원 
    3개 이상 각 1,490원 할인
    오뚜기 맛있는 떡볶이 3종 (상품별 상이) 3개 이상 구매시 1개당 각3,300원
    ※교차구매 가능 ※1개 구매시 각 4,790원
    1+1 동원 바른어묵 전골용 (350g) 4,480원
    최대 50%
    제로 대용량 탄산음료 15종 (상품별 행사 상이/1.2L~1.8L) 1,290~2,740원
    세계맥주 골라담기 26종 (상품별 용량 상이) 6캔 구매시 12,900원
    ※4캔 구매시 9,600원 ※1캔 구매시 3,000~3,300원
    정월 대보름 부럼깨고 소원빌자 
    미리 준비하는 알뜰한 2025년 정월대보름
    3봉 이상 각 1,000원 할인
    2봉 이상 각 500원 할인
    건나물 11종(상품별 용량 상이) ※교차구매 가능 ※ 건취나물/건호박/건곰취/건가지/건고구마순/건토란대/무말랭이/건더덕취/건곤드레/건고사리/건부지갱이 ※품목당 가격은 매장에서 확인하실 수 있습니다
    2개 이상 각 2,000원 할인
    오곡밥/부럼세트 (상품별 상이/원산지 별도표기) 2개 이상 구매시 1개당 각7,990/5,990원
    ※1개 구매시 각 9,990/7,990원
    ※교차구매 가능 
    왕 밤(700g/봉/국산) 7,990원
    대보름 삶은나물 6종 (400g/팩/국산) ※기간 : 2/8(토) ~ 2/12(수) 9,990원
    롯데마트GO 앱 특가!
    ▶기간 : 2/6(목) ~ 2/12(수) ※롯데마트GO앱 스캔시 행사가 적용됩니다
    GO앱 스캔시 2,000원 할인
    칠레산 블루베리 (310g/팩/칠레산) 5,990원
    GO앱 스캔시 40%
    온가족 모둠 초밥(30입) (팩/원산지 별도표기) 17,994원 
    GO앱 스캔시 50%
    봉지굴 전품목 (상품별 상이)
    GO앱 스캔시 1+1
    광천김 유아 김/김자반 7종 (상품별 규격 상이) 각3,780~7,680원
    ※동일가격 간 교차구매 가능
    2025 롯데마트 물가잡기 캠페인 The HOT 매주 꼭 필요한 상품을 The HOT하게 제안합니다
    이번주 핫프라이스 HOT
    1+1 마늘치킨 훈제 슬라이스 (400g/냉장/원산지 별도표기) 9,990원 ※기간 : 2/6(목) ~ 2/12(수)
    롯데/신한/NH농협/삼성카드 2개 이상 50%
    K-Street FOOD 인기 냉동분식/간식 먹거리 13종 (상품별 규격 상이) 해당카드로 2개 이상 구매시 1개당 해당카드로 2개 이상 구매시 1개당
    ※기간 : 2/6(목) ~ 2/12(수) ※교차구매 가능
    ※고래사, 미미네, 추억의 국민학교, 풀무원 ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 각 5,800~11,700원
    1,500원 할인 롯데/신한/NH농협/삼성카드 1+1
    매일 페레로 로쉐 콜렉션 T24(259.2g) 25,000원
    ※기간 : 2/6(목) ~ 2/14(금) ※점별 입고수량 상이 ※재고 소진시 조기 종료
    이달의 핫PB HOT
    요리하다 찰통순대 (500g) 500원 할인 2,990원
    요리하다 리얼새우볶음밥 (400g) 3,990원
    오늘좋은 꿀유자차/꿀레몬차(각 1kg) 500원 할인 각5,990원
    오늘좋은 살코기참치/고추참치(90g×4입) 2,000원 할인 각5,990

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    캐나다산 척아이롤 (100g/냉장/캐나다산) L.POINT 40%
    국내산 돼지 불고기(앞/뒷다리) (각 100g/냉장/국내산) 1,390/1,190원
    ※제주점 제외 ※조기 품절될 수 있습니다
    국내산 대패 삼겹살/목심 (각 800g/냉동/국내산) 18,900/17,900원
    ※제주점 제외 ※조기 품절될 수 있습니다
    산더미 대파 소불고기 (800g/냉장/원산지 별도표기) L.POINT 50% 14,900원
    파타고니아 생연어 (250g/냉장/칠레산) L.POINT 4,000원 10,900원
    완도 활 전복(중)(10마리/냉장/국산) L.POINT 25% 18,600원
    제첩국 번들 기획팩 (500g×2/냉장/원산지 별도표기) L.POINT 30% 6,930원
    동원 양반 들기름향이 그윽한 김 (4.5g×20봉/원산지 별도표기) 7,980원
    꽃보다 오징어 슬라이스 (160g) L.POINT 2,000원 15,990원
    2개 이상 각500원 할인
    봄나물 모음전(상품별 상이) 2개 이상 구매시 1개당 각1,990~3,490원
    ※교차구매 가능 ※1개 구매시 각2,490~3,990원
    ※냉이/달래/봄동/유채나물/청도미나리 등
    영암 친환경 꿀고구마 (1.5kg/박스/국산) 6,990원
    뉴질랜드 단호박 (통/뉴질랜드산) 3,990원
    제주 한라봉/천혜향/레드향(각1.5kg/박스/국산) 15,990/17,990/19,990원
    이스라엘산 레드자몽 (5~8입/봉/이스라엘산) 7,990원
    삼천포에 빠진 골드원 키위 (8~12입/팩/국산) 9,990원
    NEW 한통 가아아득 치킨 (1팩/원산지 별도표기) ※제주점 제외 L.POINT 25% 9,743원
    온가족 한통 가득 탕수육 (1팩/원산지 별도표기) 12,990원
    한달내내 +50% 중량 UP "가격은 그대로
    온가족 모둠 도시락 (1팩/원산지 별도표기) 14,990원
    5무 훈제오리  (1팩/원산지 별도표기) 11,193원 L.POINT 30% 
    통족발(중)(1팩/원산지 별도표기) 13,900원
    ※족발 매장 입점점에 한함
    하나사면 하나 더 1+1
    1+1 동원 가쓰오 크랩스 (144g) 3,980원
    1+1 CJ 비비고 떡볶이 3종 (각 360g, 오리지널/매운/치즈) 각5,480원
    ※교차구매 가능
    1+1 종가 맛김치 (800g) 14,900원
    1+1 롯데쉐푸드 미니돈까스 (380g) 6,980원
    1+1 하림 더미식 고기군만두(320g×2)/부추 새우 손만두(350g×2) 10,880/13,980원
    ※기간 : 2/6(목)~2/9(일) ※동일 상품에 한함
    1+1 씨제이 햇반 곤약밥 2종 (각 150g×3입, 귀리흑미/현미귀리) 각8,980원
    ※교차구매 가능
    1+1 다담 파우치양념 5종 (상품별 규격 상이) 각2,980원
    ※교차구매 가능 
    1+1 해찬들 원조 태양초 고추장(1.5kg) 19,000원
    1+1 빙그레 끼리 크림치즈 그릭스타일(200g) 8,000원
    1+1 파워에이드 1.5L 外 스포츠음료 7종(상품별 상이) ※동일 브랜드간 교차구매가능 1,980~3,980원
    1+1 달고나 쿠키/허쉬 초콜릿칩쿠키/허쉬 초콜릿칩 베리쿠키 (각 144g) ※교차구매 가능 각5,290원
    L.POINT 1+1 삼립 상쾌한아침 우유식빵/삼립 미니 꿀호떡 2종 (상품별 규격 상이) ※오리지널/옥수수 ※교차구매 가능 각3,480원
    1+1 베지밀 검은콩 고칼슘두유 (파우치)(190ml×15입) 18,750원
    1+1 대용량 과채주스 27종(1L~1.8L) 3,630~14,900원
    ※동일 브랜드 내 동일가격 간 교차구매가능
    1+1 녹차원 꿀 유자차 外 5종 (각 1kg) 각9,900원
    ※교차구매 가능 
    1+1 녹차원 생강차/쌍화차 (각 15g×30입) 각9,900원
    ※교차구매 가능
    2개 이상 구매시 더 싸게
    2개 이상 각1,980원 할인 
    CJ 육공육 후랑크 2종 (상품별 상이) 2개 이상 구매시 1개당 각5,000원
    ※교차구매 가능 ※1개 구매시 각6,980원
    2개 이상 20%
    CJ 비비고 순살 구이 (삼치/고등어, 상품별 상이) 2개 이상 구매시 1개당 각4,192원
    ※1개 구매시 각5,240원 ※교차구매 가능
    2개 이상 25%
    하남돼지집 매콤 콩나물 불고기 外 3종(상품별 상이)  2개 이상 구매시 1개당 각7,425~11,175원
    ※1개 구매시 각9,900~14,900원 ※교차구매 가능
    2개 이상 50%
    풀무원 노엣지 피자 3종 (상품별 규격 상이) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능 ※코리안BBQ/포테이토&콘/토마토&미트콤보 ※1개 구매시 각9,980원
    2개 이상 각 1,000원 할인
    CJ 고메 소바바 치킨 5종 양념순살 外 4종, 상품별 규격 상이) ※교차구매 가능
    2개 이상 구매시 1개당 각7,980원 ※1개 구매시 각8,980원
    2개 이상 20%
    오뚜기 마열라면 (4개입) 2개 이상 구매시 1개당 각3,584원
    ※1개 구매시 4,480원
    2개 이상 40%
    씨제이 햇반 솥반 5종 (상품별 규격 상이) 2개 이상 구매시 1개당 각2,988원
    ※교차구매 가능 ※1개 구매시 각4,980원
    2개 이상 50%
    샘표 고추장/된장/쌈장 전품목 15종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,245~16,490원
    ※교차구매 가능 ※점별 운영상품 상이 ※1개 구매시 각2,490~32,980원
    2개 이상 50%
    남양 불가리스 제로 3종 (각 80g×4입) 2개 이상 구매시 1개당 각1,890원
    ※딸기/복숭아/플레인 ※1개 구매시 각3,780원 ※교차구매 가능
    2개 이상 50%
    일동후디스 그릭요거트 3종 (각 80g×4입) 2개 이상 구매시 1개당 각3,200원
    ※플레인/저지방/코코포도 ※1개 구매시 각6,400원 ※교차구매 가능
    2개 이상 10%
    크라운 스낵 5종 (상품별 상이) ※교차구매 가능
    2개 이상 구매시 1개당 각1,782원 ※1개 구매시 각1,980원

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    발렌타인데이
    해당상품 롯데/신한/NH농협/삼성카드 1만 8천원 이상 결제시 5,000원 롯데상품권 증정
    ※해당 브랜드(8개사) : 롯데웰푸드/매일유업/농심/한국마즈/해태제과/크라운제과/오리온/삼경(하리보 제외)
    ※페이지 하단 카드할인 세부내용 참고 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " V "를 확인하세요 ※결제시 계산대에서 수령

    매일 페레로로쉐 T8 하트(100g)/T24 다이아(300g) 10% 8,820/22,590원
    롯데 프리미엄 가나 레몬 치즈 타르트/베리무스케익 (각 68g) 각3,490원
    하리보 스위트박스 (600g) ※상품권 증정 제외 9,980원
    1+1 네슬레 킷캣 딸기 기획 (79g) ※상품권 증정 제외 4,980원
    부샤드 나폴리탄 카라멜&씨솔트 초콜릿 틴 기획(180g) ※상품권 증정 제외 15,980원

    목우촌 프라임 소시지 2종 (상품별 상이) 각6,980원
    ※기간 : 2/1(토)~2/28(금)
    풀무원 얇은피 꽉찬속 김치/고기만두(각 800g) 각8,980원
    사조대림 실속김밥 세트(510g) 7,980원
    ※기간 : 2/1(토)~2/28(금)
    사세 치킨 가라아게(500g) 3,000원 할인 6,980원
    풀무원 체다모짜 핫도그(320g)/탱글뽀득 핫도그(400g) 2,500원 할인 각6,480원
    풀무원 식물성 지구식단 로스팅 정면 (4개입) L.POINT 45% 2,998원
    백설 고소함 가득 참기름 L.POINT 6,970원 (500ml) 7,980원
    서울 후레쉬밀크 기획(900ml×2입) 4,480원
    서울 비요뜨 4종(상품별 상이)
    ※링크/초코링/크런치볼/쿠키앤크림 각700원 할인 각2,180원
    아임비타 해당상품 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " ■ "를 확인하세요 ※결제시 계산대에서 수령
    2+1 아임비타 이뮨플러스 (23.5g×7입) 16,900원
    2개 이상 34%
    락토핏 골드/코어/슬림 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각10,494~17,754원
    ※1개 구매시 각15,900~26,900원
    남양 드빈치 자연방목 피자치즈(1kg) 13,980원
    덴마크 드링킹 요구르트 5종(각 750ml) 각3,880원
    ※베리믹스/복숭아/샤인머스켓/플레인/딸기
    아침에주스 오렌지/포도/사과 (각 1.8L) 각600원 할인 각5,980원
    인기 생필품 득템 찬스
    1+1 히말라야 핑크솔트 바디워시 3종(각 900g) 각12,980원
    ※민트/로즈/포레스트 ※점별 운영상품 상이 ※교차구매 가능
    2개 이상 50%
    엘라스틴 아미노 프로틴 클리닉 샴푸/컨디셔너 (각 855ml) 2개 이상 구매시 1개당 각6,450원
    ※샴푸 : 손상/볼륨 ※컨디셔너 : 손상 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각12,900원
    2개 이상 각 2,500원 할인
    닥터그루트 집중케어 샴푸 기획 (각 샴푸 400ml+50ml+컨디셔너 50ml) 2개 이상 구매시 1개당 각20,500원
    ※힘없는 모발용/가려운 두피용 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각23,000원
    2개 이상 50%
    갸스비 왁스 4종&스프레이 3종 (상품별 상이) 2개 이상 구매시 1개당 각4,545~6,000원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각9,090~12,000원
    카밀 핸드크림 미니기획 (30ml×3입) ※점별 재고 상이 6,900원
    우르오스 스칼프 샴푸(500ml)/스킨 밀크, 스킨로션(각 200ml) 각23,500원
    ※점별 운영상품 상이
    2개 이상 50%
    테크/피지 액체세제 11종(상품별 상이) 2개 이상 구매시 1개당 각7,100~14,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각14,200~29,900원
    2개 이상 50%
    프릴 애플 주방세제 (용기 700ml/리필 1L) ※교차구매 가능 2개 이상 구매시 1개당 각4,750원
    ※1개 구매시 각9,500원
    2개 이상 50%
    홈스타 맥스프레쉬 4종(각 900ml) ※교차구매 가능 2개 이상 구매시 1개당 각3,950원
    ※1개 구매시 각7,900원
    남성 인기 화장품 최대 30% => 10% 추가할인
    ※기간 : 2/6(목)~2/12(수)
    ※참여 브랜드(7개사) : 엘지생활건강/아모레퍼시픽/이니스프리/토니모리/미샤/더샘/엔프라니
    ※입점점에 한함 ※브랜드별 할인율 상이
    쏘피 생리대 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령
    2개 이상 50%
    쏘피 한결 생리대(팬티라이너/중형, 대형, 슈퍼롱) 2개 이상 구매시 1개당 각4,250/5,950원
    ※교차구매 가능 ※1개 구매시 각8,500/11,900원
    최대 50%
    헨켈 라벤더 해당상품
    2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉭ "를 확인하세요
    ※결제시 계산대에서 수령
    헨켈(퍼실/버넬/브레프)라벤더 대전 (상품별 상이) 각2,250~19,900원
    하기스 기저귀 전품목 10만원 이상 구매시 10,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉻ "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 35%
    하기스 기저귀(상품별 상이) 2개 이상 구매시 1개당 각19,890~29,840원
    ※교차구매 가능 ※1개 구매시 각30,600~45,900원
    피죤 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉬ "를 확인하세요
    ※결제시 계산대에서 수령
    2개 이상 50%
    피죤(액츠/피죤/무균무때)브랜드 대전 (상품별 상이) 2개 이상 구매시 1개당 각3,450~9,950원 
    ※1개 구매시 각6,900~19,900원 ※브랜드별 교차구매 가능

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    L.POINT 40% 
    해피콜 뉴골든구르메 IH 프라이팬/냄비(상품별 상이) 20,940~43,140원
    테팔/해피콜/도루코 인덕션 프라이팬 3종(각 28cm) 각20,900원
    ※기간 : 1/31(금)~2/26(수) ※테팔 리미티드/해피콜 루시드/도루코 웜그레이 프라이팬
    레녹스/프라카 식기(상품별 상이) 최대 40% 각4,830~21,540원
    1+1 기획팩 유니랩 위생롤백/장갑/지퍼백 (상품별 상이) 각3,480~5,980원
    1+1 기획팩 3M 황토숯 고무장갑/다목적 수세미(소 3입) 각3,190~4,380원
    썬 부탄가스(4입) 20% 5,430원
    2개 이상 50%
    네오플램 퓨어락(상품별 상이) 2개 이상 구매시 1개당 각2,200~6,200원 
    ※1개 구매시 각4,400~12,400원 ※교차구매 가능
    오늘좋은 냉동밥 용기 (8P) 4,900원
    오늘좋은 하트쿠션&과자 바디필로우 (상품별 상이) 20% 7,920~23,920원 
    ※점별 재고 상이 ※해당상품에 한함 ※재고 소진시 조기 종료
    오늘좋은 에어블 이불/바람솔솔 경추베개(상품별 상이) 20% 12,720~47,920원
    ※이불 - S : 150×200cm/Q : 180×220cm ※베개 - 30×50cm ※입점점에 한함 ※해당상품에 한함
    오늘좋은 아로마 세면타월(각 4입) 30% 각13,930원
    ※아쿠아/블라썸/유칼립투스/라벤더
    ※입점점에 한함 ※해당상품에 한함
    리필 추가증정
    3M 크린스틱 변기솔 리필 6입/베이킹소다 크린스틱 청소용 수세미 (상품별 상이) 5,690/10,880원
    ※입점점에 한함 ※해당상품에 한함
    오늘좋은 포켓형 장바구니 2종 20% 각2,000원
    자동차용품 클리어런스(상품별 상이) 최대 30% 각1,840~66,320원
    시들지 않는 프리저브드 안개꽃다발 (핑크/퍼플) 각19,900원
    ※손잡이 쇼핑백 포함 상품입니다
    2개 이상 50%
    하우홈 보온/단열시트 9종 ※교차구매 가능 2개 이상 구매시 1개당 각990~8,450원
    ※1개 구매시 각1,990~16,900원
    월드컵 에반/네로 운동화(상품별 상이) 각23,900/32,900원
    ※사이즈 : 230~280mm ※입점점에 한함
    브랜드 양말(상품별 상이) 2개 이상 30% 2개 이상 구매시 1개당 각7,920~8,720원
    ※1개 구매시 각6,930~7,630원
    ※교차구매 가능 ※뉴발란스, 언더아머, 휠라, 네파, MLB 등 ※점별 상품 상이
    오늘좋은 겨울 침구/담요/거실화 外 (상품별 상이) 각3,000~35,000원 
    ※입점점에 한함 ※해당상품에 한함 ※최초 판매가 기준 ※소진시 종료 ※점별 재고 상이 ※매장에 더많은 상품이 준비되어 있습니다
    오늘좋은 히트 언더웨어(1입) 각5,900~9,900원
    오늘좋은 수면/밍크스판 홈웨어 (바지/원피스/세트) 각6,900~12,900원
    산리오 外 아동 캐릭터 실내복(상품별 상이) 각6,900~21,900원
    ToysRus LEGO 이색적인 발렌타인 데이 선물 추천!
    ※기간 : 2/6(목)~2/26(수) ※입점점에 한함
    L.POINT 30%
    레고 장미(40460) (8세 이상/19.1×4.6×14.1cm) 레고 장미 꽃다발(10328) (18세 이상/26.2×9.1×38.2cm) 14,630/62,930원
    레고 핑크 꽃다발(10342) (18세 이상/26.2×7.1×38.2cm) 79,900원
    colioli 반려동물 식품 최대 30%
    시저 강아지 건사료/습식사료 23종 (상품별 상이) 최대 15% 각2,100~8,330원
    파티믹스 고양이 간식 7종 (각 60g) 30% 각3,010원

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 1월 10일(금) ~ 1월 30일(목) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202502_2";
const category = [];

const data = {
  L201: {
    title: "스마트전단지-전점201",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L203: {
    title: "스마트전단지-제타플렉스 잠실점203",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L205: {
    title: "스마트전단지-제타플렉스 서울역점205",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
