import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2025. 2. 13(목) ~ 2025. 2. 26(수) < 신선식품 적용기간 : 2025. 2. 13(목) ~ 2. 19(수) >
    어서 와, 봄 봄맞이 제철 신선 페스타 롯데마트
    1등급 한우 전품목 (각 100g/냉장/국내산 한우고기) L.POINT 최대 40%
    ※부위별 조기 품절될 수 있습니다
    딸기 전품목(상품별 상이/국산) ※교차구매 가능 ※점별 운영상품 상이 2개 이상 구매시 각 3,000원 할인
    미국산 오렌지(6~10입/봉/미국산)/이스라엘산 레드자몽(5~10입/봉/이스라엘산) 9,990/7,990원
    AI선별 한라봉/천혜향/레드향 (각 1.5kg/박스/국산) L.POINT 3,000원 16,990원
    남해안 생굴(400g/냉장/국산) L.POINT 30% 9,030원
    완도 활 전복(대/특) (마리/냉장/국산) L.POINT 25% 1,943/2,775원  
    나혼자 꼬막 무침 (200g/냉장/원산지 별도표기) L.POINT 4,000원 9,900원
    스위티오/감숙왕 바나나(송이/필리핀산) 각3,990원
    태국 남독마이 망고 (개/태국산) 3개 구매시 9,990원
    ※점별 운영과수 상이 ※1개 구매시 3,990원
    롯데마트 GO 앱 특가! ▶기간 : 2/13(목) ~ 2/19(수) ※롯데마트GO앱 스캔시 행사가 적용됩니다
    대추방울 스윗마토(청포도향) (500g/팩/국산) 4,990원 GO앱 스캔시 2,000원 할인
    껍질 벗긴 새우 (750g/냉동/원산지 별도표기) 16,730원 GO앱 스캔시 30%
    김밥&유부&샌드 한판세트 (팩/원산지 별도표기) 12,990원 GO앱 스캔시 1,000원 할인
    경남제약 구미 3종 (상품별 상이) ※교차구매 가능 19,800원 GO앱 스캔시 1+1
    2025 롯데마트 물가잡기 캠페인 The HOT 매주 꼭 필요한 상품을 The HOT하게 제안합니다
    My핫프라이스 HOT
    롯데마트 GO앱 고객님들의 소중한 투표로 채택된 국산쌀 10kg
    롯데/신한/NH농협/삼성카드 5,000원 할인
    정갈한 쌀(10kg/국산) 21,900원
    ※기간 : 2/13(목) ~ 2/16(일)
    ※L.POINT회원 기간 중 1인 1회 1포 한정 카드할인 적용
    ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고
    My핫프라이스 HOT
    롯데/신한/NH농협/삼성카드 50%
    하림 닭다리살/닭가슴살 (각 100g/냉장/원산지 별도표기) 990/890원
    ※1인 2팩 구매 한정 ※조기 품절될 수 있습니다 ※제주점 제외 ※페이지 하단 카드할인 세부내용 참고
    L.POINT 40%
    ALL NEW 새우 초밥(20입) (팩/원산지 별도표기) 11,994원
    이달의 핫 PB HOT 
    요리하다 차돌김치볶음밥(400g) L.POINT 1,000원 2,990원
    2개 이상 50%
    요리하다 얼큰한 김치우동(432g) 2개 이상 구매시 1개당 각3,495원 ※1개 구매시 6,990원
    500원 할인 오늘좋은 두 번 구워 더 구수한 누룽지(500g)/단백질바 미니(144g) 각2,490원
    공구핫딜
    공구핫딜 고래사 꼬치어묵(12입/526g) 6,940원
    공구핫딜 한성 와일드크래미(180g×3) 6,980원
    공구핫딜 순샘 레몬 주방세제(2L+2L) 7,900원

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    
  </div>
  `,
  `
  <div>
    2/15 토 2/16 일
    제주 흑돼지 삼겹살/목심 (각 100g/냉장/국내산) 각2,990원
    ※조기 품절될 수 있습니다
    제주 흑돼지 제육볶음 (800g/냉장/원산지 별도표기) ※제주점 제외 L.POINT 50% 9,900원
    미국산 초이스 척아이롤/부채살 (각 100g/냉장/미국산) L.POINT 40%
    창녕 양파품은 한우 불고기 (700g/냉장/원산지 별도표기) 17,900원
    내가 만드는 연어(횟감용/구이용) (각 100g/냉장/노르웨이산) L.POINT 30% 3,990/3,710원
    광어연어도미 모둠회 (300g 내외/냉장/원산지 별도표기) L.POINT 20% 27,920원
    손질 오징어(3마리/냉장/중국산) 9,900원
    국산 고등어자반(특대) (손/2마리/냉장/고등어, 천일염 : 국산) L.POINT 20% 5,592원
    동원 양반 참기름김/참숯구이김/곱창돌김(상품별 상이) 1+1 각12,980원
    ※교차구매 가능
    2개 이상 50%
    코주부 징기스칸 육포 순한맛/매운맛(각 130g) 2개 이상 구매시 1개당 각7,450원
    ※교차구매 가능 ※1개 구매시 각14,900원
    2개 이상 50%
    풀무원 국산 두부 단품 10종 (상품별 상이) 2개 이상 구매시 1개당 각975~2,850원
    ※점별 운영상품 상이 ※기간 : 2/13(목)~2/16(일) ※교차구매 가능
    구운아몬드(600g)/피스타치오(500g) ※원산지 별도표기 각9,990원
    상생 무(개/국산) 1,490원
    ※기간 : 2/13(목)~2/16(일) ※조기 품절될 수 있습니다
    상생 양배추(통/국산) ※기간 : 2/13(목)~2/16(일) ※조기 품절될 수 있습니다 2,990원
    2봉 이상 각 500원 할인
    청양고추/꽈리고추 (각 150g/봉/국산) 2봉 이상 구매시 1봉당 각2,490원
    ※교차구매 가능 ※1봉 구매시 각2,990원
    칠레산 블루베리(310g/팩/칠레산) 2팩 이상 구매시 각2,000원 할인
    시즈닝 순살치킨 3종 (각 1팩/원산지 별도표기) ※크런치 콘소메/크런치 시즈닝/맵짠단짠 각7,990원
    온가족 한통 가득 탕수육 (1팩/원산지 별도표기) 12,990원
    샌드위치 3종(각 1팩/원산지 별도표기) L.POINT 10% 각5,391원
    ※듬뿍햄치즈/듬뿍에그햄/키트에그 샌드위치
    로스트 오븐 치킨 (1팩/원산지 별도표기) 12,990원
    달콤한 디저트 4종(각 1팩/원산지 별도표기) 최대1,300원 할인 각3,200~4,900원
    ※부드러운 미니 화이트롤/달콤한 미니 초코롤케익/진한 바스크 치즈케이크/블루베리 바스크 치즈케이크 ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함
    하나사면 하나 더 1+1
    1+1 사조대림 8가지 풍성한 어묵탕 (850g) 12,980원
    1+1 풀무원 우리동네 떡볶이 2종 (각 330g) 각4,980원
    ※교차구매 가능
    1+1 동원 퀴진 크리스피 돈까스 (상품별 규격 상이) 각9,980원
    ※치즈/통등심 ※기간 : 2/13(목)~2/16(일) ※교차구매 가능
    1+1 CJ 비비고 수제 진한김치만두/생야채 물만두(상품별 규격 상이) 각9,480원
    ※기간 : 2/13(목)~2/16(일) ※교차구매 가능
    1+1 롯데 에센브런치 비엔나 (180g) 각5,380원
    1+1 CJ 주먹밥 3종(각 500g) 각8,980원
    ※김치치즈/불고기/참치마요 ※교차구매 가능 ※기간 : 2/13(목)~2/16(일)
    1+1 제주우유 3종(각 750ml) 각4,980원
    ※A2유기농/무항생제/저지방 ※교차구매 가능
    1+1 파스퇴르 유기농 주스 3종 (각 80ml) 각1,980원
    ※교차구매 가능
    1+1 청정원 순창 초고추장(1kg) 6,180원
    1+1 해찬들 궁중식 쇠고기 볶음 고추장/백설 고소함 가득 참기름 14,500/9,800원
    봄나물 비빔밥 필수재료!
    1+1 사조 참치액/꽃게액 5종 (상품별 규격 상이) 각7,980~13,980원
    ※점별 운영상품 상이 ※동일 상품에 한함
    1+1 청우 그랑쉘 딸기(195g) 4,680원
    1+1 프링글스 11종 (상품별 상이) ※교차구매 가능 각3,330원
    1+1 RTD 커피음료 36종 (상품별 상이) 각2,380~3,980원
    ※콘트라베이스 블랙 外 35종 ※동일 브랜드 내 동일가격 간 교차구매 가능
    1+1 베지밀 검은콩과 검은참깨 두유 (190ml×24입) 30,000원
    1+1 인기 탄산음료 4종(각 1.25L) ※롯데 칠성 사이다 外 ※교차구매 가능 각3,280원
    2개 이상 구매시 더싸게 
    2개 이상 50%
    CJ 비비고 썰은 배추김치 2종 (각 500g) 2개 이상 구매시 1개당 각4,950원
    ※교차구매 가능 ※1개 구매시 각9,900원
    2개 이상 20%
    프레시지 이지키트 5종 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각7,120~11,984원
    ※1개 구매시 각8,900~14,980원
    2개 이상 각 1,000원 할인
    CJ 비비고 통새우 만두(200g)/쌀떡볶이(575g) 2개 이상 구매시 1개당 각5,980원
    ※교차구매 가능 ※1개 구매시 각6,980원
    2개 이상 50%
    동원 딤섬 3종(상품별 규격 상이) 2개 이상 구매시 1개당 각5,490~5,990원
    ※새우하가우/부추창펀/샤오롱바오 ※교차구매 가능 ※기간 : 2/13(목)~2/19(수)
    ※1개 구매시 각10,980~11,980원
    2개 이상 각 1,000원 할인
    CJ 고메 소바바치킨 5종 (상품별 규격 상이) 2개 이상 구매시 1개당 각7,980원
    ※소이허니 순살 外 4종 ※교차구매 가능 ※1개 구매시 각8,980원
    2개 이상 25%
    대상 호밍스 국탕 7종(각 700g) 2개 이상 구매시 1개당 각9,000~10,500원
    ※우거지 순살 감자탕 外 ※교차구매 가능 ※1개 구매시 각12,000~14,000원
    2개 이상 50%
    서울 후레쉬 모짜렐라(127g) 2개 이상 구매시 1개당 각3,890원
    ※1개 구매시 각7,780원
    2개 이상 25%
    소화가 잘되는 우유/저지방우유/프로틴(각 930ml) 2개 이상 구매시 1개당 각2,835~3,060원
    ※동일가격 간 교차구매 가능 ※1개 구매시 각3,780~4,080원
    2개 이상 50%
    비요뜨 4종(상품별 상이) 2개 이상 구매시 1개당 각1,440원 
    ※초코링/크런치볼/쿠키앤크림/링크 ※교차구매 가능 ※기간 : 2/14(금)~2/16(일) ※1개 구매시 각2,880원
    컵라면 전품목 116종 2+1 롯데/신한/NH농협/삼성카드
    ※상품별 규격 상이 ※오뚜기 진라면 매운맛 소컵 6개입 外 115종
    ※기간 : 2/14(금)~2/15(토)
    ※브랜드 관계없이 교차구매 가능
    ※3개 구매시 34% 할인 ※3의 배수로 적용
    ※1인 1일 1회, 회당 9개 한정
    ※L.POINT 회원 해당카드 결제시 적용
    ※해당품목에 한함 ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
  `
  <div>
    종가 아삭하고 개운한 포기김치(3kg) 2,100원 할인 26,900원
    오뚜기 XO 교자 3종 (상품별 규격상이) ※새우&홍게살/김치/고기 각9,980원
    동원 그릴리 직화 후랑크/부어스트(각 500g) 각7,980원
    한성 크래미 (180g×2입) 6,480원
    오뚜기 열 김치우동 2인 (448g) 5,980원
    CJ 비비고 직화식 돼지불고기/제육볶음(각 400g) CJ 비비고 안동식 간장찜닭/철판닭갈비(각 700g) 9,980~10,980원
    서울 체다 슬라이스 치즈 기획 (270g×2입) 8,660원
    동원 비피더스 명장 기획 (125ml×12입) 4,980원
    서울 후레쉬밀크 기획 (900ml×2입) 4,480원
    수입 올리브오일 19종 (상품별 규격 상이) 최대6,700원 할인 8,900~30,400원
    ※점별 운영상품 상이
    크라운 스트로베리콘 (120g) 2,720원
    해태 홈런볼 말차딸기 (43g×4입) 5,580원
    오리온 마켓오 브라우니 크림치즈(240g) 5,280원
    케오데오 그린망고젤리(270g) 3,980원
    돈시몬 오렌지/적포도(각 2L) 최대1,100원 할인 9,980/6,980원 
    맥심 모카골드 제로슈거 커피 (11.5g×20입/11.5g×50입) L.POINT 최대 1,270원 4,780/11,630원
    1+1 내추럴박스 레몬즙 2종 (각 500ml) 각9,990원
    ※교차구매 가능
    종근당건강 락토핏 골드 (2g×90포) L.POINT 5,000원 17,900원
    하림산업 전품목 (더미식/푸디버디) 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉭"를 확인하세요 ※결제시 계산대에서 수령
    2+1 하림 더미식 장인라면 담백/얼큰/맵싸한맛 (각 4개입) 각8,800원
    ※교차구매 가능
    1+1 푸디버디 라면 6종/국물요리 5종 (상품별 규격 상이) 2,100~6,800원
    ※기간 : 2/13(목)~2/16(일) ※동일 가격 간 교차구매 가능
    하림 더미식 육즙새우교자/고기 군만두(315g×2/320g×2) 10,380/8,680원
    동원에프엔비 해당상품 2개 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령
    동원 크리스피 크런치 치킨 5종 (상품별 규격상이) 각8,980원
    ※ 치킨봉/순살치킨/할라피뇨 치킨봉/양념 순살치킨/스윗허니 치킨봉
    인기 생필품 득템 
    1+1 닥터버들 컴팩트헤드 타이니칫솔 (4입) 11,900원
    ※점별 운영상품 상이
    1+1 온더바디 리얼모이스처&더내추럴 바디워시(각 900g) 각9,980/11,980원
    ※점별 운영상품 상이 ※피오니, 코튼, 코코넛/레몬, 라벤더, 올리브 향 ※동일 상품에 한함 
    2개 이상 50% 
    엘라스틴 아미노펩타이드 샴푸/트리트먼트(상품별 상이) 2개 이상 구매시 1개당 각7,450~10,900원
    ※점별 운영상품 상이 ※블랑머스크향/페어앤프리지아향 ※교차구매 가능 ※1개 구매시 각14,900~21,800원
    2개 이상 50%
    오가니스트 샴푸/컨디셔너 4종 (각 500ml) 2개 이상 구매시 1개당 각7,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각15,900원
    2개 이상 각 3,000원 할인
    미쟝센 펄샤이닝 샴푸/트리트먼트 (각 1,000ml) 2개 이상 구매시 1개당 각6,900원 
    ※화이트머스크향/피오니향 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각9,900원
    2080 어드밴스드(각 420g) ※블루/그린/진지발리스 치약 4종(각 120g×3입) 2개 이상 구매시 1개당 각4,450~5,950원
    ※오리지날/허벌민트 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각8,900~11,900원
    2개 이상 50%
    액츠/피죤 9종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,950~9,990원 
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각9,900~19,980원
    2개 이상 50%
    퍼실/퍼울/버넬 13종(상품별 상이) 2개 이상 구매시 1개당 각3,450~9,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각6,900~20,900원
    2개 이상 50%
    좋은 느낌 좋은순면+4P증량기획 (중형/대형) 2개 이상 구매시 1개당 각7,450원 
    ※점별 한정 수량 ※교차구매 가능 ※1개 구매시 각14,900원
    2개 이상 50%
    순수한면 제로 생리대 4종 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각4,000~4,400원
    ※1개 구매시 각8,000~8,800원
    2개 이상 50%
    크리넥스 수프림소프트 3겹 화장지 (27m×30롤) 2개 이상 구매시 1개당 각19,700원
    ※1개 구매시 각39,400원
    온더바디 퓨어더마 바디로션(각 1,000ml) 각10,980원
    ※파우더/그린허브/무향 ※점별 운영상품 상이
    메디힐 대용량 마스크팩(각 20매) 각10,900원
    ※하이드라수딩/바이탈퍼밍 ※점별 운영상품 상이
    피지오겔 DMT 크림/AI 크림 (150ml/100ml) 26,910/25,650원
    봄맞이 리빙플렉스 청소/위생용품 11대브랜드
    롯데/신한/NH농협/삼성카드 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉣"를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    주방세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,400~7,950원
    ※해당상품에 한함 ※동일 브랜드에 한함 ※1개 구매시 각4,800~15,900원
    최대 50%
    유한락스/홈스타/브레프 등 주거 세정제(상품별 상이) 2,250~9,900원
    ※해당상품에 한함 ※동일 브랜드에 한해 교차구매 가능
    2개 이상 50%
    깨끗한나라 세프 키친타월 (130매×6롤) 2개 이상 구매시 1개당 각5,450원
    ※1개 구매시 10,900원
    2개 이상 50%
    비트 트리플액션 용기(각 2.8L, 일반/드럼) 2개 이상 구매시 1개당 각6,950원
    ※점별 운영상품 상이 ※교차구매 가능
    ※1개 구매시 각13,900원
    스카트 청소물티슈 4종(상품별 상이) 2,900~3,200원
    크리넥스 수앤수 더도톰 플러스 코튼클린 물티슈(72매×6입) 5,900원 할인 10,000원
    엘지생활건강 간편세제 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉠"를 확인하세요 ※결제시 계산대에서 수령
    피지/테크 캡슐세제 5종 (상품별 상이) 2개 이상 구매시 1개당 각9,950~14,450원 
    ※1개 구매시 각19,900~28,900원 ※점별 운영상품 상이 ※교차구매 가능
    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div> 
    화이트/스파클링/로제와인 롯데/신한/NH농협/삼성카드 5만원 이상 구매시 5,000원 추가할인
    ※기간 : 2/13(목) ~ 2/14(금) ※L.POINT 회원에 한함 ※일부품목 제외 ※1일 1회 한정 ※페이지 하단 카드할인 세부내용 참고
    달위니 15년(700ml) L.POINT 46,200원 82,800원
    스텔라 아르투아(740ml) 4캔 구매시 11,000원
    ※1캔 구매시 3,900원
    6캔 구매시 10%
    국민맥주 3종(각 500ml, 라거/에일/바이젠) 6캔 구매시 1캔당 각1,620~1,800원
    ※1캔 구매시 각1,800~2,000원
    봄맞이 리빙플렉스 BIG세일 
    ※매장 내 빅세일존에 더 많은 상품이 준비되어 있습니다
    롯데/신한/NH농협/삼성카드 4만원 이상 구매시 5,000원 롯데상품권 증정 
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " P "를 확인하세요 ※결제시 계산대에서 수령
    브랜드 인덕션 프라이팬 4종(각 28cm) 각20,900원
    ※테팔 리미티드, 에브리데이쿡/해피콜 루시드/도루코 웜그레이
    1+1 기획팩 크린랩 크린롤백(중/대) 6,090/8,090원
    클리어플러스 트라이탄 밀폐용기 2P 기획(상품별 상이) 5,900~9,900원
    2개 이상 20%
    오늘좋은 냉동밥 보관용기(8P) 2개 이상 구매시 1개당 각3,920원
    ※1개 구매시 4,900원
    아이리스 시스템 수납함 2/3호 (상품별 상이) 50% 11,450/13,950원
    오늘좋은 클리어 리빙박스(35L/60L/80L) 40% 8,340~14,340원
    10% 1+1 기획팩 
    3M 더블액션 플러스 물걸레 청소포 (각 2입, 표준형/대형) 15,570/18,090원
    ※점별 물량 상이 ※조기 품절될 수 있습니다
    3M 테이프클리너 리필 기획 (16cm×8m/6입) 30% 10,390원
    ※입점점에 한함 ※해당상품에 한함
    2개 이상 50%
    오늘좋은 메모리폼 베개/룸바이홈 스칼럽 패드 外(상품별 상이) 2개 이상 구매시 1개당 각10,950~13,950원
    ※교차구매 가능 ※입점점에 한함 ※해당상품에 한함 ※1개 구매시 각 21,900~27,900원
    오늘좋은 개별스위치 멀티탭 (3구 1M/3구 3M/3구 5M) 30% 7,630~13,230원
    ※상품권 증정행사는 롯데/신한/NH농협/삼성카드로 3구 상품 결제 고객에 한함
    롬바이홈 럭셔리 호텔타월(각 4P) 30% 각18,130원
    ※그레이/네이비 ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    2개 이상 40%
    코코도르 화이트라벨 디퓨저 (각 200ml) 2개 이상 구매시 1개당 각5,340원
    ※교차구매 가능 ※1개 구매시 각 8,900원
    오늘좋은 세라믹 프라이팬/냄비 20% 21,520~39,120원
    2개 이상 30%
    코렐 목련/시나모롤/더블링그린티 2개 이상 구매시 1개당 각6,930~15,330원
    ※1개 구매시 9,900~21,900원 ※교차구매 가능
    롯데/신한/NH농협/삼성카드 20%
    2025 봄맞이 원예대전
    시클라멘 외 화초 19종 5,520~10,320원
    ※행사상품에 한함 ※페이지 하단 카드할인 세부내용 참고
    2개 이상 30%
    오늘좋은/룸바이홈 옷걸이 11종 (상품별 상이) 2개 이상 구매시 1개당 각2,030~4,130원
    ※교차구매 가능 ※1개 구매시 각 2,900~5,900원
    공구핫딜 벡셀 알카라인 (각 26입, AA/AAA) 각9,900원
    신학기 준비는 롯데마트에서
    나이키 헤리티지 러닝 힙팩 6,000원 할인 23,900원
    ※입점점에 한함 
    신학기 학습용 스탠드 10종 (상품별 상이) 최대 30% 11,830~39,920원
    책상의자 전품목(상품별 상이) 10,000원 할인 39,900~129,000원
    써모스 텀블러 30% 20,650~27,650원
    ※마이디자인보틀/퀵스트레이트/데일리 원터치 파우더    
    신학기 가방 모음전(상품별 상이) 각2,000~39,900원
    ※점별 입점상품 상이 ※입점점에 한함
    최대 4,000원 할인
    시나모롤/쿠로미/포차코 아동 실내화 각9,900원
    ※점별 입점상품 상이
    신학기 아동/주니어 캐릭터, 브랜드 양말(각 3~5매) 각7,900원
    오늘좋은 굿찬스 반팔 티셔츠/런닝 각12,900~13,900원
    도브 해당상품 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " ㉥ "를 확인하세요 ※결제시 계산대에서 수령
    도브 베이비 바디로션/워시 5종 40% 9,200~9,600원
    (각 400ml) ※점별 운영상품 상이
    2개 이상 50%
    하기스 기저귀(상품별 상이) 2개 이상 구매시 1개당 각15,300~22,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각 30,600~45,900원
    이나바 브랜드 고양이사료&간식 85종(상품별 상이) 20% 1,040~31,200원
    반려동물 식/용품
    콜리올리 깔끔하게 사용하는 1회용 패드 3종(M 100매/M 200매/L 40매) 20% 9,520~19,120 원
    ToysRus LEGO 고속기차 L.POINT 30%
    레고 고속기차(60337) (7세 이상/58.2×37.8×10.3cm) L.POINT 30% 139,930원
    ※입점점에 한함
    ToysRus X 코리아보드게임즈 L.POINT 최대 30%
    ※입점점에 한함 ※점별 재고 상이 ※오프라인에 한함
    그래비트랙스 코어 스타터:밸런스 L.POINT 30% 118,230원
    쿼리도 팩맨 L.POINT 30% 32,830원

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    
  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2025. 2. 13(목) ~ 2025. 2. 26(수) < 신선식품 적용기간 : 2025. 2. 13(목) ~ 2. 19(수) >
    ·제타플렉스 잠실점
    어서와, 봄 봄맞이 제철 신선 페스타 제타플렉스
    1등급 한우 전품목 (각 100g/냉장/국내산 한우고기) L.POINT 최대 40%
    ※부위별 조기 품절될 수 있습니다
    남해안 생굴(400g/냉장/국산) 9,030원 L.POINT 30%
    딸기 전품목(상품별 상이/국산) 2개 이상 구매시 각 3,000원 할인
    ※교차구매 가능 ※점별 운영상품 상이
    완도 활 전복(대/특) (마리/냉장/국산) L.POINT 25% 1,943/2,775원
    나혼자 꼬막 무침 (200g/냉장/원산지 별도표기) L.POINT 4,000원 9,900원
    미국산 오렌지(6~10입/봉/미국산)/이스라엘산 레드자몽(5~10입/봉/이스라엘산) 9,990/7,990원
    AI선별 한라봉/천혜향/레드향(각 1.5kg/박스/국산) L.POINT 3,000원 16,990원
    스위티오/감숙왕 바나나(송이/필리핀산) 각3,990원
    태국 남독마이 망고 (개/태국산) 3개 구매시 9,990원 
    ※점별 운영과수 상이 ※1개 구매시 3,990원
    롯데마트 GO앱 특가! 
    ▶기간 : 2/13(목) ~ 2/19(수) ※롯데마트GO앱 스캔시 행사가 적용됩니다
    대추방울 스윗마토(청포도향) (500g/팩/국산) GO앱 스캔시 2,000원 할인 4,990원
    껍질 벗긴 새우 (750g/냉동/원산지 별도표기) GO앱 스캔시 30% 16,730원
    김밥&유부&샌드 한판세트 (팩/원산지 별도표기) GO앱 스캔시 1,000원 할인 12,990원
    경남제약 구미 3종 (상품별 상이) ※교차구매 가능 19,800원 GO앱 스캔시 1+1
    2025 롯데마트 물가잡기 캠페인 The HOT
    매주 꼭 필요한 상품을 The HOT하게 제안합니다.
    My 핫프라이스 HOT
    롯데마트 GO앱 고객님들의 소중한 투표로 채택된 국산쌀 10KG
    롯데/신한/NH농협/삼성카드 5,000원 할인
    정갈한 쌀(10kg/국산) 21,900원
    ※기간 : 2/13(목) ~ 2/16(일)
    ※L.POINT회원 기간 중 1인 1회 1포 한정 카드할인 적용
    ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고
    My 핫프라이스 HOT
    롯데/신한/NH농협/삼성카드 50%
    하림 닭다리살/닭가슴살 (각 100g/냉장/원산지 별도표기) 990/890원 
    ※1인 2팩 구매 한정 ※조기 품절될 수 있습니다 ※제주점 제외 ※페이지 하단 카드할인 세부내용 참고
    L.POINT 40% ALL NEW 새우 초밥(20입) (팩/원산지 별도표기) 11,994원
    이달의 핫PB HOT
    L.POINT 1,000원
    요리하다 차돌김치볶음밥(400g) 2,990원
    2개 이상 50% 
    요리하다 얼큰한 김치우동(432g) 2개 이상 구매시 1개당 각3,495원
    ※1개 구매시 6,990원
    500원 할인
    오늘좋은 두 번 구워 더 구수한 누룽지(500g)/단백질바 미니(144g) 각2,490원
    공구핫딜
    공구핫딜 고래사 꼬치어묵(12입/526g) 6,940원
    공구핫딜 한성 와일드크래미(180g×3) 6,980원
    공구핫딜 순샘 레몬 주방세제(2L+2L) 7,900원

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    2/15 토 2/16 일
    제주 흑돼지 삼겹살/목심 (각 100g/냉장/국내산) 각2,990원
    ※조기 품절될 수 있습니다
    제주 흑돼지 제육볶음 (800g/냉장/원산지 별도표기) ※제주점 제외 L.POINT 50% 9,900원
    미국산 초이스 척아이롤/부채살 (각 100g/냉장/미국산) L.POINT 40%
    창녕 양파품은 한우 불고기 (700g/냉장/원산지 별도표기) 17,900원
    내가 만드는 연어(횟감용/구이용) (각 100g/냉장/노르웨이산) L.POINT 30% 3,990/3,710원
    광어연어도미 모둠회 (300g 내외/냉장/원산지 별도표기) L.POINT 20% 27,920원
    손질 오징어(3마리/냉장/중국산) 9,900원
    국산 고등어자반(특대) (손/2마리/냉장/고등어, 천일염 : 국산) L.POINT 20% 5,592원
    동원 양반 참기름김/참숯구이김/곱창돌김(상품별 상이) 1+1 각12,980원
    ※교차구매 가능
    2개 이상 50%
    코주부 징기스칸 육포 순한맛/매운맛(각 130g) 2개 이상 구매시 1개당 각7,450원
    ※교차구매 가능 ※1개 구매시 각14,900원
    2개 이상 50%
    풀무원 국산 두부 단품 10종 (상품별 상이) 2개 이상 구매시 1개당 각975~2,850원
    ※점별 운영상품 상이 ※기간 : 2/13(목)~2/16(일) ※교차구매 가능
    구운아몬드(600g)/피스타치오(500g) ※원산지 별도표기 각9,990원
    상생 무(개/국산) 1,490원
    ※기간 : 2/13(목)~2/16(일) ※조기 품절될 수 있습니다
    상생 양배추(통/국산) ※기간 : 2/13(목)~2/16(일) ※조기 품절될 수 있습니다 2,990원
    2봉 이상 각 500원 할인
    청양고추/꽈리고추 (각 150g/봉/국산) 2봉 이상 구매시 1봉당 각2,490원
    ※교차구매 가능 ※1봉 구매시 각2,990원
    칠레산 블루베리(310g/팩/칠레산) 2팩 이상 구매시 각2,000원 할인
    시즈닝 순살치킨 3종 (각 1팩/원산지 별도표기) ※크런치 콘소메/크런치 시즈닝/맵짠단짠 각7,990원
    온가족 한통 가득 탕수육 (1팩/원산지 별도표기) 12,990원
    샌드위치 3종(각 1팩/원산지 별도표기) L.POINT 10% 각5,391원
    ※듬뿍햄치즈/듬뿍에그햄/키트에그 샌드위치
    로스트 오븐 치킨 (1팩/원산지 별도표기) 12,990원
    달콤한 디저트 4종(각 1팩/원산지 별도표기) 최대1,300원 할인 각3,200~4,900원
    ※부드러운 미니 화이트롤/달콤한 미니 초코롤케익/진한 바스크 치즈케이크/블루베리 바스크 치즈케이크 ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함
    하나사면 하나 더 1+1
    1+1 사조대림 8가지 풍성한 어묵탕 (850g) 12,980원
    1+1 풀무원 우리동네 떡볶이 2종 (각 330g) 각4,980원
    ※교차구매 가능
    1+1 동원 퀴진 크리스피 돈까스 (상품별 규격 상이) 각9,980원
    ※치즈/통등심 ※기간 : 2/13(목)~2/16(일) ※교차구매 가능
    1+1 CJ 비비고 수제 진한김치만두/생야채 물만두(상품별 규격 상이) 각9,480원
    ※기간 : 2/13(목)~2/16(일) ※교차구매 가능
    1+1 롯데 에센브런치 비엔나 (180g) 각5,380원
    1+1 CJ 주먹밥 3종(각 500g) 각8,980원
    ※김치치즈/불고기/참치마요 ※교차구매 가능 ※기간 : 2/13(목)~2/16(일)
    1+1 제주우유 3종(각 750ml) 각4,980원
    ※A2유기농/무항생제/저지방 ※교차구매 가능
    1+1 파스퇴르 유기농 주스 3종 (각 80ml) 각1,980원
    ※교차구매 가능
    1+1 청정원 순창 초고추장(1kg) 6,180원
    1+1 해찬들 궁중식 쇠고기 볶음 고추장/백설 고소함 가득 참기름 14,500/9,800원
    봄나물 비빔밥 필수재료!
    1+1 사조 참치액/꽃게액 5종 (상품별 규격 상이) 각7,980~13,980원
    ※점별 운영상품 상이 ※동일 상품에 한함
    1+1 청우 그랑쉘 딸기(195g) 4,680원
    1+1 프링글스 11종 (상품별 상이) ※교차구매 가능 각3,330원
    1+1 RTD 커피음료 36종 (상품별 상이) 각2,380~3,980원
    ※콘트라베이스 블랙 外 35종 ※동일 브랜드 내 동일가격 간 교차구매 가능
    1+1 베지밀 검은콩과 검은참깨 두유 (190ml×24입) 30,000원
    1+1 인기 탄산음료 4종(각 1.25L) ※롯데 칠성 사이다 外 ※교차구매 가능 각3,280원
    2개 이상 구매시 더싸게 
    2개 이상 50%
    CJ 비비고 썰은 배추김치 2종 (각 500g) 2개 이상 구매시 1개당 각4,950원
    ※교차구매 가능 ※1개 구매시 각9,900원
    2개 이상 20%
    프레시지 이지키트 5종 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각7,120~11,984원
    ※1개 구매시 각8,900~14,980원
    2개 이상 각 1,000원 할인
    CJ 비비고 통새우 만두(200g)/쌀떡볶이(575g) 2개 이상 구매시 1개당 각5,980원
    ※교차구매 가능 ※1개 구매시 각6,980원
    2개 이상 50%
    동원 딤섬 3종(상품별 규격 상이) 2개 이상 구매시 1개당 각5,490~5,990원
    ※새우하가우/부추창펀/샤오롱바오 ※교차구매 가능 ※기간 : 2/13(목)~2/19(수)
    ※1개 구매시 각10,980~11,980원
    2개 이상 각 1,000원 할인
    CJ 고메 소바바치킨 5종 (상품별 규격 상이) 2개 이상 구매시 1개당 각7,980원
    ※소이허니 순살 外 4종 ※교차구매 가능 ※1개 구매시 각8,980원
    2개 이상 25%
    대상 호밍스 국탕 7종(각 700g) 2개 이상 구매시 1개당 각9,000~10,500원
    ※우거지 순살 감자탕 外 ※교차구매 가능 ※1개 구매시 각12,000~14,000원
    2개 이상 50%
    서울 후레쉬 모짜렐라(127g) 2개 이상 구매시 1개당 각3,890원
    ※1개 구매시 각7,780원
    2개 이상 25%
    소화가 잘되는 우유/저지방우유/프로틴(각 930ml) 2개 이상 구매시 1개당 각2,835~3,060원
    ※동일가격 간 교차구매 가능 ※1개 구매시 각3,780~4,080원
    2개 이상 50%
    비요뜨 4종(상품별 상이) 2개 이상 구매시 1개당 각1,440원 
    ※초코링/크런치볼/쿠키앤크림/링크 ※교차구매 가능 ※기간 : 2/14(금)~2/16(일) ※1개 구매시 각2,880원
    컵라면 전품목 116종 2+1 롯데/신한/NH농협/삼성카드
    ※상품별 규격 상이 ※오뚜기 진라면 매운맛 소컵 6개입 外 115종
    ※기간 : 2/14(금)~2/15(토)
    ※브랜드 관계없이 교차구매 가능
    ※3개 구매시 34% 할인 ※3의 배수로 적용
    ※1인 1일 1회, 회당 9개 한정
    ※L.POINT 회원 해당카드 결제시 적용
    ※해당품목에 한함 ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    종가 아삭하고 개운한 포기김치(3kg) 2,100원 할인 26,900원
    오뚜기 XO 교자 3종 (상품별 규격상이) ※새우&홍게살/김치/고기 각9,980원
    동원 그릴리 직화 후랑크/부어스트(각 500g) 각7,980원
    한성 크래미 (180g×2입) 6,480원
    오뚜기 열 김치우동 2인 (448g) 5,980원
    CJ 비비고 직화식 돼지불고기/제육볶음(각 400g) CJ 비비고 안동식 간장찜닭/철판닭갈비(각 700g) 9,980~10,980원
    서울 체다 슬라이스 치즈 기획 (270g×2입) 8,660원
    동원 비피더스 명장 기획 (125ml×12입) 4,980원
    서울 후레쉬밀크 기획 (900ml×2입) 4,480원
    수입 올리브오일 19종 (상품별 규격 상이) 최대6,700원 할인 8,900~30,400원
    ※점별 운영상품 상이
    크라운 스트로베리콘 (120g) 2,720원
    해태 홈런볼 말차딸기 (43g×4입) 5,580원
    오리온 마켓오 브라우니 크림치즈(240g) 5,280원
    케오데오 그린망고젤리(270g) 3,980원
    돈시몬 오렌지/적포도(각 2L) 최대1,100원 할인 9,980/6,980원 
    맥심 모카골드 제로슈거 커피 (11.5g×20입/11.5g×50입) L.POINT 최대 1,270원 4,780/11,630원
    1+1 내추럴박스 레몬즙 2종 (각 500ml) 각9,990원
    ※교차구매 가능
    종근당건강 락토핏 골드 (2g×90포) L.POINT 5,000원 17,900원
    하림산업 전품목 (더미식/푸디버디) 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉭"를 확인하세요 ※결제시 계산대에서 수령
    2+1 하림 더미식 장인라면 담백/얼큰/맵싸한맛 (각 4개입) 각8,800원
    ※교차구매 가능
    1+1 푸디버디 라면 6종/국물요리 5종 (상품별 규격 상이) 2,100~6,800원
    ※기간 : 2/13(목)~2/16(일) ※동일 가격 간 교차구매 가능
    하림 더미식 육즙새우교자/고기 군만두(315g×2/320g×2) 10,380/8,680원
    동원에프엔비 해당상품 2개 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령
    동원 크리스피 크런치 치킨 5종 (상품별 규격상이) 각8,980원
    ※ 치킨봉/순살치킨/할라피뇨 치킨봉/양념 순살치킨/스윗허니 치킨봉
    인기 생필품 득템 
    1+1 닥터버들 컴팩트헤드 타이니칫솔 (4입) 11,900원
    ※점별 운영상품 상이
    1+1 온더바디 리얼모이스처&더내추럴 바디워시(각 900g) 각9,980/11,980원
    ※점별 운영상품 상이 ※피오니, 코튼, 코코넛/레몬, 라벤더, 올리브 향 ※동일 상품에 한함 
    2개 이상 50% 
    엘라스틴 아미노펩타이드 샴푸/트리트먼트(상품별 상이) 2개 이상 구매시 1개당 각7,450~10,900원
    ※점별 운영상품 상이 ※블랑머스크향/페어앤프리지아향 ※교차구매 가능 ※1개 구매시 각14,900~21,800원
    2개 이상 50%
    오가니스트 샴푸/컨디셔너 4종 (각 500ml) 2개 이상 구매시 1개당 각7,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각15,900원
    2개 이상 각 3,000원 할인
    미쟝센 펄샤이닝 샴푸/트리트먼트 (각 1,000ml) 2개 이상 구매시 1개당 각6,900원 
    ※화이트머스크향/피오니향 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각9,900원
    2080 어드밴스드(각 420g) ※블루/그린/진지발리스 치약 4종(각 120g×3입) 2개 이상 구매시 1개당 각4,450~5,950원
    ※오리지날/허벌민트 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각8,900~11,900원
    2개 이상 50%
    액츠/피죤 9종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,950~9,990원 
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각9,900~19,980원
    2개 이상 50%
    퍼실/퍼울/버넬 13종(상품별 상이) 2개 이상 구매시 1개당 각3,450~9,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각6,900~20,900원
    2개 이상 50%
    좋은 느낌 좋은순면+4P증량기획 (중형/대형) 2개 이상 구매시 1개당 각7,450원 
    ※점별 한정 수량 ※교차구매 가능 ※1개 구매시 각14,900원
    2개 이상 50%
    순수한면 제로 생리대 4종 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각4,000~4,400원
    ※1개 구매시 각8,000~8,800원
    2개 이상 50%
    크리넥스 수프림소프트 3겹 화장지 (27m×30롤) 2개 이상 구매시 1개당 각19,700원
    ※1개 구매시 각39,400원
    온더바디 퓨어더마 바디로션(각 1,000ml) 각10,980원
    ※파우더/그린허브/무향 ※점별 운영상품 상이
    메디힐 대용량 마스크팩(각 20매) 각10,900원
    ※하이드라수딩/바이탈퍼밍 ※점별 운영상품 상이
    피지오겔 DMT 크림/AI 크림 (150ml/100ml) 26,910/25,650원
    봄맞이 리빙플렉스 청소/위생용품 11대브랜드
    롯데/신한/NH농협/삼성카드 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉣"를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    주방세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,400~7,950원
    ※해당상품에 한함 ※동일 브랜드에 한함 ※1개 구매시 각4,800~15,900원
    최대 50%
    유한락스/홈스타/브레프 등 주거 세정제(상품별 상이) 2,250~9,900원
    ※해당상품에 한함 ※동일 브랜드에 한해 교차구매 가능
    2개 이상 50%
    깨끗한나라 세프 키친타월 (130매×6롤) 2개 이상 구매시 1개당 각5,450원
    ※1개 구매시 10,900원
    2개 이상 50%
    비트 트리플액션 용기(각 2.8L, 일반/드럼) 2개 이상 구매시 1개당 각6,950원
    ※점별 운영상품 상이 ※교차구매 가능
    ※1개 구매시 각13,900원
    스카트 청소물티슈 4종(상품별 상이) 2,900~3,200원
    크리넥스 수앤수 더도톰 플러스 코튼클린 물티슈(72매×6입) 5,900원 할인 10,000원
    엘지생활건강 간편세제 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉠"를 확인하세요 ※결제시 계산대에서 수령
    피지/테크 캡슐세제 5종 (상품별 상이) 2개 이상 구매시 1개당 각9,950~14,450원 
    ※1개 구매시 각19,900~28,900원 ※점별 운영상품 상이 ※교차구매 가능
    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    스텔라 아르투아(740ml) 4캔 구매시 11,000원
    ※1캔 구매시 3,900원
    6캔 구매시 10%
    국민맥주 3종(각 500ml, 라거/에일/바이젠) 6캔 구매시 1캔당 각1,620~1,800원
    ※1캔 구매시 각1,800~2,000원
    봄맞이 리빙플렉스 BIG세일 ※매장 내 빅세일존에 더 많은 상품이 준비되어 있습니다
    롯데/신한/NH농협/삼성카드 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " P "를 확인하세요 ※결제시 계산대에서 수령
    브랜드 인덕션 프라이팬 4종(각 28cm) 각20,900원
    ※테팔 리미티드, 에브리데이쿡/해피콜 루시드/도루코 웜그레이
    1+1 기획팩
    크린랩 크린롤백(중/대) 6,090/8,090원
    클리어플러스 트라이탄 밀폐용기 2P 기획(상품별 상이) 5,900~9,900원
    2개 이상 20%
    오늘좋은 냉동밥 보관용기(8P) 2개 이상 구매시 1개당 각3,920원
    ※1개 구매시 4,900원
    아이리스 시스템 수납함 2/3호 (상품별 상이) 50% 11,450/13,950원
    오늘좋은 클리어 리빙박스(35L/60L/80L) 40% 8,340~14,340원
    3M 더블액션 플러스 물걸레 청소포 (각 2입, 표준형/대형) 10% 1+1 기획팩 15,570/18,090원
    ※점별 물량 상이 ※조기 품절될 수 있습니다
    3M 테이프클리너 리필 기획 (16cm×8m/6입) 30% 10,390원
    ※입점점에 한함 ※해당상품에 한함
    2개 이상 50%
    오늘좋은 메모리폼 베개/룸바이홈 스칼럽 패드 外(상품별 상이) 2개 이상 구매시 1개당 각10,950~13,950원
    ※교차구매 가능 ※입점점에 한함 ※해당상품에 한함 ※1개 구매시 각 21,900~27,900원
    오늘좋은 개별스위치 멀티탭 (3구 1M/3구 3M/3구 5M) 30% 7,630~13,230원
    ※ 상품권 증정행사는 롯데/신한/NH농협/삼성카드로 3구 상품 결제 고객에 한함
    롬바이홈 럭셔리 호텔타월(각 4P) 30% 각18,130원
    ※그레이/네이비 ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    코코도르 화이트라벨 디퓨저 (각 200ml) 2개 이상 구매시 1개당 각5,340원
    ※교차구매 가능 ※1개 구매시 각 8,900원
    2개 이상 30%
    코렐 목련/시나모롤/더블링그린티 2개 이상 구매시 1개당 각6,930~15,330원
    ※1개 구매시 9,900~21,900원 ※교차구매 가능
    롯데/신한/NH농협/삼성카드 20% 2025 봄맞이 원예대전
    시클라멘 외 화초 19종 5,520~10,320원
    ※행사상품에 한함 ※페이지 하단 카드할인 세부내용 참고
    2개 이상 30%
    오늘좋은/룸바이홈 옷걸이 11종 (상품별 상이) 2개 이상 구매시 1개당 각2,030~4,130원
    ※교차구매 가능 ※1개 구매시 각 2,900~5,900원
    공구핫딜 벡셀 알카라인 (각 26입, AA/AAA) 각9,900원
    신학기 준비는 롯데마트에서
    나이키 헤리티지 러닝 힙팩 ※입점점에 한함 6,000원 할인 23,900원
    신학기 학습용 스탠드 10종 (상품별 상이) 최대 30% 11,830~39,920원
    책상의자 전품목(상품별 상이) 10,000원 할인 39,900~129,000원
    써모스 텀블러 30% 20,650~27,650원
    ※마이디자인보틀/퀵스트레이트/데일리 원터치 파우더
    신학기 가방 모음전(상품별 상이) 각2,000~39,900원
    ※점별 입점상품 상이 ※입점점에 한함
    시나모롤/쿠로미/포차코 아동 실내화 최대 4,000원 할인 각9,900원
    ※점별 입점상품 상이
    신학기 아동/주니어 캐릭터, 브랜드 양말(각 3~5매) 각7,900원
    오늘좋은 굿찬스 반팔 티셔츠/런닝 각12,900~13,900원
    도브 해당상품 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " ㉥ "를 확인하세요 ※결제시 계산대에서 수령
    도브 베이비 바디로션/워시 5종 (각 400ml) 40% 9,200~9,600원
    ※점별 운영상품 상이
    하기스 기저귀(상품별 상이) 2개 이상 50% 2개 이상 구매시 1개당 각15,300~22,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각 30,600~45,900원
    이나바 브랜드 고양이사료&간식 85종(상품별 상이) 20% 1,040~31,200원
    반려동물 식/용품
    콜리올리 깔끔하게 사용하는 1회용 패드 3종(M 100매/M 200매/L 40매) 20% 9,520~19,120원
    ToysRus LEGO 고속기차 L.POINT 30%
    L.POINT 30%
    레고 고속기차(60337) (7세 이상/58.2×37.8×10.3cm) 139,930원
    ※입점점에 한함
    ToysRus X 코리아보드게임즈
    코리아보드게임즈 전품목 L.POINT 최대 30%
    그래비트랙스 코어 스타터:밸런스 L.POINT 30% 118,230원
    쿼리도 팩맨 L.POINT 30% 32,830원

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaSeoulData = [
  `<h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2025. 2. 13(목) ~ 2025. 2. 26(수) < 신선식품 적용기간 : 2025. 2. 13(목) ~ 2. 19(수) >
    ·제타플렉스 서울역점
    어서와, 봄 봄맞이 제철 신선 페스타 제타플렉스 서울역점
    1등급 한우 전품목 (각 100g/냉장/국내산 한우고기) L.POINT 최대 40%
    ※부위별 조기 품절될 수 있습니다
    남해안 생굴(400g/냉장/국산) 9,030원 L.POINT 30%
    딸기 전품목(상품별 상이/국산) 2개 이상 구매시 각 3,000원 할인
    ※교차구매 가능 ※점별 운영상품 상이
    완도 활 전복(대/특) (마리/냉장/국산) L.POINT 25% 1,943/2,775원
    나혼자 꼬막 무침 (200g/냉장/원산지 별도표기) L.POINT 4,000원 9,900원
    미국산 오렌지(6~10입/봉/미국산)/이스라엘산 레드자몽(5~10입/봉/이스라엘산) 9,990/7,990원
    AI선별 한라봉/천혜향/레드향(각 1.5kg/박스/국산) L.POINT 3,000원 16,990원
    스위티오/감숙왕 바나나(송이/필리핀산) 각3,990원
    태국 남독마이 망고 (개/태국산) 3개 구매시 9,990원 
    ※점별 운영과수 상이 ※1개 구매시 3,990원
    롯데마트 GO앱 특가! 
    ▶기간 : 2/13(목) ~ 2/19(수) ※롯데마트GO앱 스캔시 행사가 적용됩니다
    대추방울 스윗마토(청포도향) (500g/팩/국산) GO앱 스캔시 2,000원 할인 4,990원
    껍질 벗긴 새우 (750g/냉동/원산지 별도표기) GO앱 스캔시 30% 16,730원
    김밥&유부&샌드 한판세트 (팩/원산지 별도표기) GO앱 스캔시 1,000원 할인 12,990원
    경남제약 구미 3종 (상품별 상이) ※교차구매 가능 19,800원 GO앱 스캔시 1+1
    2025 롯데마트 물가잡기 캠페인 The HOT
    매주 꼭 필요한 상품을 The HOT하게 제안합니다.
    My 핫프라이스 HOT
    롯데마트 GO앱 고객님들의 소중한 투표로 채택된 국산쌀 10KG
    롯데/신한/NH농협/삼성카드 5,000원 할인
    정갈한 쌀(10kg/국산) 21,900원
    ※기간 : 2/13(목) ~ 2/16(일)
    ※L.POINT회원 기간 중 1인 1회 1포 한정 카드할인 적용
    ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고
    My 핫프라이스 HOT
    롯데/신한/NH농협/삼성카드 50%
    하림 닭다리살/닭가슴살 (각 100g/냉장/원산지 별도표기) 990/890원 
    ※1인 2팩 구매 한정 ※조기 품절될 수 있습니다 ※제주점 제외 ※페이지 하단 카드할인 세부내용 참고
    L.POINT 40% ALL NEW 새우 초밥(20입) (팩/원산지 별도표기) 11,994원
    이달의 핫PB HOT
    L.POINT 1,000원
    요리하다 차돌김치볶음밥(400g) 2,990원
    2개 이상 50% 
    요리하다 얼큰한 김치우동(432g) 2개 이상 구매시 1개당 각3,495원
    ※1개 구매시 6,990원
    500원 할인
    오늘좋은 두 번 구워 더 구수한 누룽지(500g)/단백질바 미니(144g) 각2,490원
    공구핫딜
    공구핫딜 고래사 꼬치어묵(12입/526g) 6,940원
    공구핫딜 한성 와일드크래미(180g×3) 6,980원
    공구핫딜 순샘 레몬 주방세제(2L+2L) 7,900원

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    2/15 토 2/16 일
    제주 흑돼지 삼겹살/목심 (각 100g/냉장/국내산) 각2,990원
    ※조기 품절될 수 있습니다
    제주 흑돼지 제육볶음 (800g/냉장/원산지 별도표기) ※제주점 제외 L.POINT 50% 9,900원
    미국산 초이스 척아이롤/부채살 (각 100g/냉장/미국산) L.POINT 40%
    창녕 양파품은 한우 불고기 (700g/냉장/원산지 별도표기) 17,900원
    내가 만드는 연어(횟감용/구이용) (각 100g/냉장/노르웨이산) L.POINT 30% 3,990/3,710원
    광어연어도미 모둠회 (300g 내외/냉장/원산지 별도표기) L.POINT 20% 27,920원
    손질 오징어(3마리/냉장/중국산) 9,900원
    국산 고등어자반(특대) (손/2마리/냉장/고등어, 천일염 : 국산) L.POINT 20% 5,592원
    동원 양반 참기름김/참숯구이김/곱창돌김(상품별 상이) 1+1 각12,980원
    ※교차구매 가능
    2개 이상 50%
    코주부 징기스칸 육포 순한맛/매운맛(각 130g) 2개 이상 구매시 1개당 각7,450원
    ※교차구매 가능 ※1개 구매시 각14,900원
    2개 이상 50%
    풀무원 국산 두부 단품 10종 (상품별 상이) 2개 이상 구매시 1개당 각975~2,850원
    ※점별 운영상품 상이 ※기간 : 2/13(목)~2/16(일) ※교차구매 가능
    구운아몬드(600g)/피스타치오(500g) ※원산지 별도표기 각9,990원
    상생 무(개/국산) 1,490원
    ※기간 : 2/13(목)~2/16(일) ※조기 품절될 수 있습니다
    상생 양배추(통/국산) ※기간 : 2/13(목)~2/16(일) ※조기 품절될 수 있습니다 2,990원
    2봉 이상 각 500원 할인
    청양고추/꽈리고추 (각 150g/봉/국산) 2봉 이상 구매시 1봉당 각2,490원
    ※교차구매 가능 ※1봉 구매시 각2,990원
    칠레산 블루베리(310g/팩/칠레산) 2팩 이상 구매시 각2,000원 할인
    시즈닝 순살치킨 3종 (각 1팩/원산지 별도표기) ※크런치 콘소메/크런치 시즈닝/맵짠단짠 각7,990원
    온가족 한통 가득 탕수육 (1팩/원산지 별도표기) 12,990원
    샌드위치 3종(각 1팩/원산지 별도표기) L.POINT 10% 각5,391원
    ※듬뿍햄치즈/듬뿍에그햄/키트에그 샌드위치
    로스트 오븐 치킨 (1팩/원산지 별도표기) 12,990원
    달콤한 디저트 4종(각 1팩/원산지 별도표기) 최대1,300원 할인 각3,200~4,900원
    ※부드러운 미니 화이트롤/달콤한 미니 초코롤케익/진한 바스크 치즈케이크/블루베리 바스크 치즈케이크 ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함
    하나사면 하나 더 1+1
    1+1 사조대림 8가지 풍성한 어묵탕 (850g) 12,980원
    1+1 풀무원 우리동네 떡볶이 2종 (각 330g) 각4,980원
    ※교차구매 가능
    1+1 동원 퀴진 크리스피 돈까스 (상품별 규격 상이) 각9,980원
    ※치즈/통등심 ※기간 : 2/13(목)~2/16(일) ※교차구매 가능
    1+1 CJ 비비고 수제 진한김치만두/생야채 물만두(상품별 규격 상이) 각9,480원
    ※기간 : 2/13(목)~2/16(일) ※교차구매 가능
    1+1 롯데 에센브런치 비엔나 (180g) 각5,380원
    1+1 CJ 주먹밥 3종(각 500g) 각8,980원
    ※김치치즈/불고기/참치마요 ※교차구매 가능 ※기간 : 2/13(목)~2/16(일)
    1+1 제주우유 3종(각 750ml) 각4,980원
    ※A2유기농/무항생제/저지방 ※교차구매 가능
    1+1 파스퇴르 유기농 주스 3종 (각 80ml) 각1,980원
    ※교차구매 가능
    1+1 청정원 순창 초고추장(1kg) 6,180원
    1+1 해찬들 궁중식 쇠고기 볶음 고추장/백설 고소함 가득 참기름 14,500/9,800원
    봄나물 비빔밥 필수재료!
    1+1 사조 참치액/꽃게액 5종 (상품별 규격 상이) 각7,980~13,980원
    ※점별 운영상품 상이 ※동일 상품에 한함
    1+1 청우 그랑쉘 딸기(195g) 4,680원
    1+1 프링글스 11종 (상품별 상이) ※교차구매 가능 각3,330원
    1+1 RTD 커피음료 36종 (상품별 상이) 각2,380~3,980원
    ※콘트라베이스 블랙 外 35종 ※동일 브랜드 내 동일가격 간 교차구매 가능
    1+1 베지밀 검은콩과 검은참깨 두유 (190ml×24입) 30,000원
    1+1 인기 탄산음료 4종(각 1.25L) ※롯데 칠성 사이다 外 ※교차구매 가능 각3,280원
    2개 이상 구매시 더싸게 
    2개 이상 50%
    CJ 비비고 썰은 배추김치 2종 (각 500g) 2개 이상 구매시 1개당 각4,950원
    ※교차구매 가능 ※1개 구매시 각9,900원
    2개 이상 20%
    프레시지 이지키트 5종 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각7,120~11,984원
    ※1개 구매시 각8,900~14,980원
    2개 이상 각 1,000원 할인
    CJ 비비고 통새우 만두(200g)/쌀떡볶이(575g) 2개 이상 구매시 1개당 각5,980원
    ※교차구매 가능 ※1개 구매시 각6,980원
    2개 이상 50%
    동원 딤섬 3종(상품별 규격 상이) 2개 이상 구매시 1개당 각5,490~5,990원
    ※새우하가우/부추창펀/샤오롱바오 ※교차구매 가능 ※기간 : 2/13(목)~2/19(수)
    ※1개 구매시 각10,980~11,980원
    2개 이상 각 1,000원 할인
    CJ 고메 소바바치킨 5종 (상품별 규격 상이) 2개 이상 구매시 1개당 각7,980원
    ※소이허니 순살 外 4종 ※교차구매 가능 ※1개 구매시 각8,980원
    2개 이상 25%
    대상 호밍스 국탕 7종(각 700g) 2개 이상 구매시 1개당 각9,000~10,500원
    ※우거지 순살 감자탕 外 ※교차구매 가능 ※1개 구매시 각12,000~14,000원
    2개 이상 50%
    서울 후레쉬 모짜렐라(127g) 2개 이상 구매시 1개당 각3,890원
    ※1개 구매시 각7,780원
    2개 이상 25%
    소화가 잘되는 우유/저지방우유/프로틴(각 930ml) 2개 이상 구매시 1개당 각2,835~3,060원
    ※동일가격 간 교차구매 가능 ※1개 구매시 각3,780~4,080원
    2개 이상 50%
    비요뜨 4종(상품별 상이) 2개 이상 구매시 1개당 각1,440원 
    ※초코링/크런치볼/쿠키앤크림/링크 ※교차구매 가능 ※기간 : 2/14(금)~2/16(일) ※1개 구매시 각2,880원
    컵라면 전품목 116종 2+1 롯데/신한/NH농협/삼성카드
    ※상품별 규격 상이 ※오뚜기 진라면 매운맛 소컵 6개입 外 115종
    ※기간 : 2/14(금)~2/15(토)
    ※브랜드 관계없이 교차구매 가능
    ※3개 구매시 34% 할인 ※3의 배수로 적용
    ※1인 1일 1회, 회당 9개 한정
    ※L.POINT 회원 해당카드 결제시 적용
    ※해당품목에 한함 ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    종가 아삭하고 개운한 포기김치(3kg) 2,100원 할인 26,900원
    오뚜기 XO 교자 3종 (상품별 규격상이) ※새우&홍게살/김치/고기 각9,980원
    동원 그릴리 직화 후랑크/부어스트(각 500g) 각7,980원
    한성 크래미 (180g×2입) 6,480원
    오뚜기 열 김치우동 2인 (448g) 5,980원
    CJ 비비고 직화식 돼지불고기/제육볶음(각 400g) CJ 비비고 안동식 간장찜닭/철판닭갈비(각 700g) 9,980~10,980원
    서울 체다 슬라이스 치즈 기획 (270g×2입) 8,660원
    동원 비피더스 명장 기획 (125ml×12입) 4,980원
    서울 후레쉬밀크 기획 (900ml×2입) 4,480원
    수입 올리브오일 19종 (상품별 규격 상이) 최대6,700원 할인 8,900~30,400원
    ※점별 운영상품 상이
    크라운 스트로베리콘 (120g) 2,720원
    해태 홈런볼 말차딸기 (43g×4입) 5,580원
    오리온 마켓오 브라우니 크림치즈(240g) 5,280원
    케오데오 그린망고젤리(270g) 3,980원
    돈시몬 오렌지/적포도(각 2L) 최대1,100원 할인 9,980/6,980원 
    맥심 모카골드 제로슈거 커피 (11.5g×20입/11.5g×50입) L.POINT 최대 1,270원 4,780/11,630원
    1+1 내추럴박스 레몬즙 2종 (각 500ml) 각9,990원
    ※교차구매 가능
    종근당건강 락토핏 골드 (2g×90포) L.POINT 5,000원 17,900원
    하림산업 전품목 (더미식/푸디버디) 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉭"를 확인하세요 ※결제시 계산대에서 수령
    2+1 하림 더미식 장인라면 담백/얼큰/맵싸한맛 (각 4개입) 각8,800원
    ※교차구매 가능
    1+1 푸디버디 라면 6종/국물요리 5종 (상품별 규격 상이) 2,100~6,800원
    ※기간 : 2/13(목)~2/16(일) ※동일 가격 간 교차구매 가능
    하림 더미식 육즙새우교자/고기 군만두(315g×2/320g×2) 10,380/8,680원
    동원에프엔비 해당상품 2개 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령
    동원 크리스피 크런치 치킨 5종 (상품별 규격상이) 각8,980원
    ※ 치킨봉/순살치킨/할라피뇨 치킨봉/양념 순살치킨/스윗허니 치킨봉
    인기 생필품 득템 
    1+1 닥터버들 컴팩트헤드 타이니칫솔 (4입) 11,900원
    ※점별 운영상품 상이
    1+1 온더바디 리얼모이스처&더내추럴 바디워시(각 900g) 각9,980/11,980원
    ※점별 운영상품 상이 ※피오니, 코튼, 코코넛/레몬, 라벤더, 올리브 향 ※동일 상품에 한함 
    2개 이상 50% 
    엘라스틴 아미노펩타이드 샴푸/트리트먼트(상품별 상이) 2개 이상 구매시 1개당 각7,450~10,900원
    ※점별 운영상품 상이 ※블랑머스크향/페어앤프리지아향 ※교차구매 가능 ※1개 구매시 각14,900~21,800원
    2개 이상 50%
    오가니스트 샴푸/컨디셔너 4종 (각 500ml) 2개 이상 구매시 1개당 각7,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각15,900원
    2개 이상 각 3,000원 할인
    미쟝센 펄샤이닝 샴푸/트리트먼트 (각 1,000ml) 2개 이상 구매시 1개당 각6,900원 
    ※화이트머스크향/피오니향 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각9,900원
    2080 어드밴스드(각 420g) ※블루/그린/진지발리스 치약 4종(각 120g×3입) 2개 이상 구매시 1개당 각4,450~5,950원
    ※오리지날/허벌민트 ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각8,900~11,900원
    2개 이상 50%
    액츠/피죤 9종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,950~9,990원 
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각9,900~19,980원
    2개 이상 50%
    퍼실/퍼울/버넬 13종(상품별 상이) 2개 이상 구매시 1개당 각3,450~9,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각6,900~20,900원
    2개 이상 50%
    좋은 느낌 좋은순면+4P증량기획 (중형/대형) 2개 이상 구매시 1개당 각7,450원 
    ※점별 한정 수량 ※교차구매 가능 ※1개 구매시 각14,900원
    2개 이상 50%
    순수한면 제로 생리대 4종 (상품별 상이) ※교차구매 가능 2개 이상 구매시 1개당 각4,000~4,400원
    ※1개 구매시 각8,000~8,800원
    2개 이상 50%
    크리넥스 수프림소프트 3겹 화장지 (27m×30롤) 2개 이상 구매시 1개당 각19,700원
    ※1개 구매시 각39,400원
    온더바디 퓨어더마 바디로션(각 1,000ml) 각10,980원
    ※파우더/그린허브/무향 ※점별 운영상품 상이
    메디힐 대용량 마스크팩(각 20매) 각10,900원
    ※하이드라수딩/바이탈퍼밍 ※점별 운영상품 상이
    피지오겔 DMT 크림/AI 크림 (150ml/100ml) 26,910/25,650원
    봄맞이 리빙플렉스 청소/위생용품 11대브랜드
    롯데/신한/NH농협/삼성카드 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉣"를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    주방세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,400~7,950원
    ※해당상품에 한함 ※동일 브랜드에 한함 ※1개 구매시 각4,800~15,900원
    최대 50%
    유한락스/홈스타/브레프 등 주거 세정제(상품별 상이) 2,250~9,900원
    ※해당상품에 한함 ※동일 브랜드에 한해 교차구매 가능
    2개 이상 50%
    깨끗한나라 세프 키친타월 (130매×6롤) 2개 이상 구매시 1개당 각5,450원
    ※1개 구매시 10,900원
    2개 이상 50%
    비트 트리플액션 용기(각 2.8L, 일반/드럼) 2개 이상 구매시 1개당 각6,950원
    ※점별 운영상품 상이 ※교차구매 가능
    ※1개 구매시 각13,900원
    스카트 청소물티슈 4종(상품별 상이) 2,900~3,200원
    크리넥스 수앤수 더도톰 플러스 코튼클린 물티슈(72매×6입) 5,900원 할인 10,000원
    엘지생활건강 간편세제 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉠"를 확인하세요 ※결제시 계산대에서 수령
    피지/테크 캡슐세제 5종 (상품별 상이) 2개 이상 구매시 1개당 각9,950~14,450원 
    ※1개 구매시 각19,900~28,900원 ※점별 운영상품 상이 ※교차구매 가능
    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    스텔라 아르투아(740ml) 4캔 구매시 11,000원
    ※1캔 구매시 3,900원
    6캔 구매시 10%
    국민맥주 3종(각 500ml, 라거/에일/바이젠) 6캔 구매시 1캔당 각1,620~1,800원
    ※1캔 구매시 각1,800~2,000원
    봄맞이 리빙플렉스 BIG세일 ※매장 내 빅세일존에 더 많은 상품이 준비되어 있습니다
    롯데/신한/NH농협/삼성카드 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " P "를 확인하세요 ※결제시 계산대에서 수령
    브랜드 인덕션 프라이팬 4종(각 28cm) 각20,900원
    ※테팔 리미티드, 에브리데이쿡/해피콜 루시드/도루코 웜그레이
    1+1 기획팩
    크린랩 크린롤백(중/대) 6,090/8,090원
    클리어플러스 트라이탄 밀폐용기 2P 기획(상품별 상이) 5,900~9,900원
    2개 이상 20%
    오늘좋은 냉동밥 보관용기(8P) 2개 이상 구매시 1개당 각3,920원
    ※1개 구매시 4,900원
    아이리스 시스템 수납함 2/3호 (상품별 상이) 50% 11,450/13,950원
    오늘좋은 클리어 리빙박스(35L/60L/80L) 40% 8,340~14,340원
    3M 더블액션 플러스 물걸레 청소포 (각 2입, 표준형/대형) 10% 1+1 기획팩 15,570/18,090원
    ※점별 물량 상이 ※조기 품절될 수 있습니다
    3M 테이프클리너 리필 기획 (16cm×8m/6입) 30% 10,390원
    ※입점점에 한함 ※해당상품에 한함
    2개 이상 50%
    오늘좋은 메모리폼 베개/룸바이홈 스칼럽 패드 外(상품별 상이) 2개 이상 구매시 1개당 각10,950~13,950원
    ※교차구매 가능 ※입점점에 한함 ※해당상품에 한함 ※1개 구매시 각 21,900~27,900원
    오늘좋은 개별스위치 멀티탭 (3구 1M/3구 3M/3구 5M) 30% 7,630~13,230원
    ※ 상품권 증정행사는 롯데/신한/NH농협/삼성카드로 3구 상품 결제 고객에 한함
    롬바이홈 럭셔리 호텔타월(각 4P) 30% 각18,130원
    ※그레이/네이비 ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    코코도르 화이트라벨 디퓨저 (각 200ml) 2개 이상 구매시 1개당 각5,340원
    ※교차구매 가능 ※1개 구매시 각 8,900원
    2개 이상 30%
    코렐 목련/시나모롤/더블링그린티 2개 이상 구매시 1개당 각6,930~15,330원
    ※1개 구매시 9,900~21,900원 ※교차구매 가능
    롯데/신한/NH농협/삼성카드 20% 2025 봄맞이 원예대전
    시클라멘 외 화초 19종 5,520~10,320원
    ※행사상품에 한함 ※페이지 하단 카드할인 세부내용 참고
    2개 이상 30%
    오늘좋은/룸바이홈 옷걸이 11종 (상품별 상이) 2개 이상 구매시 1개당 각2,030~4,130원
    ※교차구매 가능 ※1개 구매시 각 2,900~5,900원
    공구핫딜 벡셀 알카라인 (각 26입, AA/AAA) 각9,900원
    신학기 준비는 롯데마트에서
    나이키 헤리티지 러닝 힙팩 ※입점점에 한함 6,000원 할인 23,900원
    신학기 학습용 스탠드 10종 (상품별 상이) 최대 30% 11,830~39,920원
    책상의자 전품목(상품별 상이) 10,000원 할인 39,900~129,000원
    써모스 텀블러 30% 20,650~27,650원
    ※마이디자인보틀/퀵스트레이트/데일리 원터치 파우더
    신학기 가방 모음전(상품별 상이) 각2,000~39,900원
    ※점별 입점상품 상이 ※입점점에 한함
    시나모롤/쿠로미/포차코 아동 실내화 최대 4,000원 할인 각9,900원
    ※점별 입점상품 상이
    신학기 아동/주니어 캐릭터, 브랜드 양말(각 3~5매) 각7,900원
    오늘좋은 굿찬스 반팔 티셔츠/런닝 각12,900~13,900원
    도브 해당상품 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " ㉥ "를 확인하세요 ※결제시 계산대에서 수령
    도브 베이비 바디로션/워시 5종 (각 400ml) 40% 9,200~9,600원
    ※점별 운영상품 상이
    하기스 기저귀(상품별 상이) 2개 이상 50% 2개 이상 구매시 1개당 각15,300~22,950원
    ※점별 운영상품 상이 ※교차구매 가능 ※1개 구매시 각 30,600~45,900원
    이나바 브랜드 고양이사료&간식 85종(상품별 상이) 20% 1,040~31,200원
    반려동물 식/용품
    콜리올리 깔끔하게 사용하는 1회용 패드 3종(M 100매/M 200매/L 40매) 20% 9,520~19,120원
    ToysRus LEGO 고속기차 L.POINT 30%
    L.POINT 30%
    레고 고속기차(60337) (7세 이상/58.2×37.8×10.3cm) 139,930원
    ※입점점에 한함
    ToysRus X 코리아보드게임즈
    코리아보드게임즈 전품목 L.POINT 최대 30%
    그래비트랙스 코어 스타터:밸런스 L.POINT 30% 118,230원
    쿼리도 팩맨 L.POINT 30% 32,830원

    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202502_3";
const category = [];

const data = {
  L301: {
    title: "스마트전단지-전점301",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L303: {
    title: "스마트전단지-제타플렉스 잠실점303",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L305: {
    title: "스마트전단지-제타플렉스 서울역점305",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
