/* 209 롯데슈퍼(~2/12) */
const superData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2025. 2. 6(목) ~ 2025. 2. 12(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이
    롯데슈퍼 맛 좋은 신선과 간편식 추천!
    명절 끝! 집밥 솔루션
    2개 이상 각 500원 할인
    봄나물 모음전(상품별 용량 상이/국산) 2개 이상 구매시 1개당 각1,990~3,490원
    ※냉이/달래/봄동/유채나물/청도미나리 등 ※교차구매 가능
    ※1개 구매시 각 2,490~3,990원
    L.POINT 30%
    국내산 돼지 불고기(앞/뒷다리) (각 100g/냉장/국내산)
    L.POINT 최대 40%
    호주산 와규 전품목 (각 100g/냉장/호주산 소고기)
    ※윗등심 40%할인, 그 외 부위 30% 할인 ※조기 품절될 수 있습니다
    롯데/신한/삼성카드 1,700원 할인
    맑은물에 국산 실속두부(520g/국산) 1,990원
    ※기간 : 2/7(금) ~ 2/8(토) ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고
    L.POINT 3,000원 파타고니아 생연어 (250g/냉장/칠레산) 11,900원
    풀무원 우동/칼국수/수제비 8종 (상품별 상이) 2개 구매시 10,000원
    ※교차구매 가능 ※풀무원 가케우동 2인 407.6g 外 7종
    ※1개 구매시 각 5,990원
    CJ 함박스테이크/미트볼 3종 (상품별 상이) 2개 구매시 3,990원
    ※교차구매 가능 ※CJ고메함박스테이크 152g 外 2종
    ※1개 구매시 각 2,990원
    1+1 씨제이 햇반 곤약밥 2종 (각 150g×3입) 각9,480원
    ※교차구매 가능
    롯데/신한/삼성카드 1,500원 할인
    하림 용가리치킨+치킨너겟(300g+300g) 6,490원
    ※페이지 하단 카드할인 세부내용 참고
    롯데 의성마늘비엔나 (160g×2) 4,490원
    정월 대보름 부럼깨고 소원빌자
    미리 준비하는 알뜰한 2025년 정월대보름
    2봉 이상 각 2,000원 할인
    부럼세트 (280g/원산지 별도표기) 2봉 이상 구매시 1봉당 각5,990원
    ※1봉 구매시 각 7,990원
    ※찰오곡밥/피땅콩/피호두/부럼세트 교차구매 가능 ※조기 품절될 수 있습니다
    2봉 이상 각 2,000원 할인
    부럼세트 (280g/원산지 별도표기) 2봉 이상 구매시 1봉당 각7,990원
    ※1봉 구매시 9,990원 ※찰오곡밥/피땅콩/피호두/부럼세트 교차구매 가능
    2봉 이상 각 2,000원 할인
    피땅콩 (100g/450g, 국산/중국산) 2봉 이상 구매시 1봉당 각5,990원
    ※1봉 구매시 각 7,990원 ※찰오곡밥/피땅콩/피호두/부럼세트 교차구매 가능
    2봉 이상 각 2,000원 할인
    피호두 (200g/원산지 별도표기)  2봉 이상 구매시 1봉당 각5,990원
    ※1봉 구매시 7,990원 ※찰오곡밥/피땅콩/피호두/부럼세트 교차구매 가능    
    롯데/신한/삼성 카드 8,000원 할인
    경기미(20kg/포/국산) 49,900원
    ※기간 : 2/6(목) ~ 2/12(수)
    ※조기 품절될 수 있습니다 ※점별 취급상품 상이 ※페이지 하단 카드할인 세부내용 참고
    왕 밤(700g/봉/국산) 7,990원
    2팩 이상 각 1,000원 할인
    대보름 삶은나물 6종(400g/팩/국산) 2팩 이상 구매시 1팩당 각9,990원
    ※1팩 구매시 11,990원 ※기간 : 2/8(토) ~ 2/12(수) ※삶은나물/건시래기 교차구매 가능
    2박스 이상 각 2,000원 할인
    양구펀치볼 건시래기 (300g/박스/국산) 2박스 이상 구매시 1박스당 각7,990원
    ※1박스 구매시 9,990원 ※삶은나물/건시래기 교차구매 가능
    2025 롯데슈퍼 물가잡기 캠페인 The HOT 매주 꼭 필요한 상품을 The HOT하게 제안합니다.
    이번주 핫프라이스 HOT 
    L.POINT 50% 롯데/신한/삼성카드 2,000원 추가할인
    다향 훈제오리 슬라이스 (400g/냉장/원산지 별도표기) 6,900원
    ※페이지 하단 카드할인 세부내용 참고
    1+1 골드셀렉션 쿠키 2종 (각 90g, 버터/초콜릿) 각1,980원
    ※교차구매 가능
    1+1 흑깨소미(560g) 11,900원
    ※한정수량
    이달의 핫 PB HOT 
    요리하다 리얼 새우 볶음밥(400g) 3,990원
    요리하다 찰통순대 (500g) 300원 할인 3,290원 
    오늘좋은 꿀유자차(1kg) 1,000원 할인 각5,990원
    오늘좋은 버터갈릭 새우칩/블랙페퍼 쉬림프 새우칩(각 155g) 각2,000원
    공구핫딜 
    공구핫딜 고래사 꼬치어묵 중량UP 기획상품(526g) 6,940원
    케라시스 비듬케어 쿨링 샴푸 2종(각 1,000ml, 가려움완화/딥클린) 각5,900원
    샤워메이트 플라워퍼퓸 바디워시(1,200ml/옐로우) 4,900원
    명진 고무장갑 3켤레(중) 5,000원
    ※취급점 한정

    ※정상가란? 2025년 1월 16일(목) ~ 2월 5일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    대추방울토마토/스위텔토마토(상품별 상이/국산) L.POINT 1,000원 할인
    한판딸기/상생딸기 (각 500g/팩/국산) L.POINT 2,000원 롯데/신한/삼성카드 1,000원 추가할인
    ※페이지 하단 카드할인 세부내용 참고
    제주 한라봉/천혜향/레드향 (각 1.5kg/박스/국산) 15,990/18,990/20,990원
    산더미 대파 소불고기 (800g/냉장/원산지 별도표기) L.POINT 50% 15,900원 
    감자 (1.5kg/박스/국산) 7,990원
    한가득 상추 (300g/팩/국산) 2,990원
    2팩 이상 각 1,000원 할인
    한끼 대패 삼겹살(300g/냉동/국내산) 2팩 이상 구매시 1팩당 각7,990원
    ※조기 품절될 수 있습니다 ※1팩 구매시 8,990원
    한우 국거리/불고기(1등급) (각 100g/냉장/국내산 한우고기) 롯데/신한/삼성카드 20%
    ※페이지 하단 카드할인 세부내용 참고 
    1+1 마늘치킨 슬라이스 (400g/냉장/원산지 별도표기) 11,900원
    봉지굴(260g/냉장/국산) L.POINT 50% 4,950원
    동원 규격 데친문어 (200g/팩/냉장/원산지 별도표기) L.POINT 2,500원 8,490원
    하나사면 하나 더 1+1
    1+1 CJ 해찬들 새콤달콤 초고추장(1.05kg) 8,980원 
    1+1 백설 고소함가득 참기름(320ml) 10,900원 
    1+1 풀무원 점보 밀떡볶이 (383.5g) 5,990원
    1+1 떠먹는 요구르트 15종(상품별 상이) 각3,780~5,290원
    ※빙그레 3종/매일 3종/다논 6종/남양 3종
    ※브랜드별 동일상품 교차구매 가능
    1+1 오뚜기 지역식 국물요리 5종(각 500g) ※교차구매 가능 각6,480원
    하림 더미식 김치/육즙고기 교자 (각 350g×2) ※교차구매 가능 각10,990원
    1+1 롯데 칠성/펩시 페트 5종 (각 1.25L) ※교차구매 가능 각3,580원 
    1+1 매일 뼈로가는 칼슘치즈 (180g) 7,190원
    1+1 레모나 에스산 (각 1.5g×90포) 각21,800원
    1+1 녹차원 꿀 유자차/레몬생강차 (각 1kg) ※교차구매 가능 각9,900원
    1+1 웅진 티즐제로 2종(각 1.5L) 각3,680원
    ※피치우롱티/딸기루이보스 ※교차구매 가능
    1+1 세꼼마 핸드워시 (각 250ml, 파우더/레몬) ※교차구매 가능 각6,900원
    동원 비엔나 소세지기획 (300g×2) 6,480원
    티코밀크/초코/조안나바닐라/빙그레 투게더 딸기(상품별 상이) 2개 구매시 10,000원
    ※1개 구매시 각5,990원
    버드와이저/스텔라(캔 473ml×6) 2,000원 할인 각12,900원
    2+1 미에로 화이바 스파클링 제로(350ml) 1,900원
    발렌타인데이 해당상품 1만 8천원 이상 결제시 5,000원 롯데상품권 증정
    ※해당 브랜드(8개사) : 롯데웰푸드/매일유업/농심/한국마즈/해태제과/크라운제과/오리온/엔제이
    ※기간 : 1/31(금)~2/14(금) ※L.POINT 회원에 한함 ※행사상품에 한함
    ※개인정보 활용 및 SMS 수신 동의 고객에 한함 ※롯데멤버스 제 3자 개인정보 제공 동의자에 한함
    ※단일 결제건에 한함 ※영수증 합산 불가 ※2/26(수) 문자 순차 발송
    롯데 프리미엄 가나 레몬 치즈 타르트/베리무스케익(각 68g) 각3,490원
    린트 린도볼 (각 200g, 밀크/어쏘티드) 각11,980원
    갈리안 시셀(125g) 8,800원
    엘라스틴 아미노프로틴 샴푸/린스 3종(각 855ml) 각7,990원
    롯데/신한/삼성카드 1,000원 할인
    잘풀리는집 순수 화이트 (30m×30롤) 14,900원
    2개 이상 50%
    프릴 맑은 식초용기 (각 700ml) 2개 이상 구매시 1개당 각4,750원 
    ※교차구매 가능 ※1개 구매시 각9,500원 
    화이트와인 9,900원 
    ※기간 : 2/6(목)~2/15(토) ※취금점에 한함
    롯데/신한/삼성카드 7,000원 할인
    간치아 모스카토 다스티 (750ml) 9,900원
    조닌모스카토 다스티 (750ml) 롯데/신한/삼성카드 5,000원 할인 9,900원
    칸티모스카토 다스티 (750ml) 롯데/신한/삼성카드 3,000원 할인 9,900원
    오늘 뭐먹지? ※델리 운영점에 한함
    온가족 모둠초밥 (30입/원산지 별도표기) 16,900원
    한통가득 탕수육 (팩/원산지 별도표기) 12,990원
    훈제오리구이 (반마리/원산지 별도표기) 6,990원

    ※정상가란? 2025년 1월 16일(목) ~ 2월5일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

/* 309 롯데슈퍼(~2/19) */
const superNewData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2025. 2. 13(목) ~ 2025. 2. 19(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이
    어서와, 봄 제철 신선 페스타 롯데슈퍼
    한판딸기/상생딸기 (각 500g/팩/국산) L.POINT 2,000원 -> 롯데/신한/삼성카드 1,000원 추가할인
    봉지굴(150g/냉장/국산) L.POINT 1,500원 3,990원
    ※페이지 하단 카드할인 세부내용 참고
    한우 등심(1등급) (100g/ 냉장/국내산 한우고기) L.POINT 43% 롯데/신한/삼성카드 7%
    ※페이지 하단 카드할인 세부내용 참고
    한우 국거리/불고기(1등급) (각 100g/ 냉장/국내산 한우고기) 롯데/신한/삼성카드 30%
    ※페이지 하단 카드할인 세부내용 참고
    1+1 동원 간편한끼 훈제연어 (150g/냉장/칠레산) 11,900원
    농할할인 20%
    상생 무(개/국산) 1,592원
    ※ L.POINT 회원 기간 중 1인 최대 2만원 할인
    L.POINT 30% 롯데/신한/삼성카드 20% 추가할인
    2/14(금) 단 하루! 초특가 반값!
    오뚜기 김치라면(120g×5입) 1,740원
    ※페이지 하단 카드할인 세부내용 참고
    2/15(토) 단 하루! 초특가 반값!
    L.POINT 30% -> 롯데/신한/삼성카드 20% 추가할인
    오뚜기 스낵면(108g×5입) 1,640원
    ※페이지 하단 카드할인 세부내용 참고
    L.POINT 2,000원
    손질 새꼬막 (1kg/냉장/국산) 9,990원
    L.POINT 2,000원
    손질오징어 (3마리/팩/냉장/중국산) 9,900원
    미국산 오렌지 (6~10입/봉/미국산) 9,990원
    L.POINT 2,000원
    AI선별 한라봉/천혜향/레드향 (각 1.5kg/박스/국산) 17,990원
    실속 청양고추/꽈리고추 (250g/200g, 봉/국산) 각3,490원
    뉴질랜드 단호박 (개/뉴질랜드산) 3,490원
    내일농장 내가 처음집는 무항생제 1+등급란(15입/국산) 4,990원
    칠레산 블루베리 (310g/팩/칠레산) 7,990원
    고산지 바나나(송이/필리핀산) 3,990원
    2025 롯데슈퍼 물가잡기 캠페인 The HOT 매주 꼭 필요한 상품을 The HOT하게 제안합니다
    My핫프라이스 HOT
    롯데마트GO앱 고객님들의 소주한 투표로 채택된 국산쌀 10Kg
    롯데/신한/삼성카드 5,000원 할인
    정갈한 쌀(10kg/국산) 21,900원
    ※기간 : 2/13(목) ~ 2/16(일) ※조기 품절될 수 있습니다 ※점별 취급 상품 상이 ※페이지 하단 카드할인 세부내용 참고
    이번주 핫프라이스 HOT
    1+1 하림 닭가슴살 스테이크 (270g/냉장/원산지 별도표기) 5,990원
    이달의 핫PB HOT
    L.POINT 1,000원
    요리하다 차돌 김치볶음밥(400g) 2,990원
    2개 이상 50%
    요리하다 얼큰한 김치우동(432g) 2개 이상 구매시 1개당 각3,495원 ※1개 구매시 6,990원
    요리하다 육개장/미역국(각 500g) 300원 할인 각2,990원
    오늘좋은 단백질바 미니(144g) 2,490원
    ※정상가란? 2025년 1월 23일(목) ~ 2월 12일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
  `
  <div>
    청정 제주 축산물 모음!
    제주 흑돼지 삼겹살/목심 (각 100g/냉장/국내산) 각3,490원
    ※기간 : 2/15(토)~2/16(일) ※조기 품절될 수 있습니다 
    제주 흑돼지 제육불고기 (800g/냉장/원산지 별도표기) L.POINT 50% 9,900원
    최강록 나야 시리즈 3종 (각 250g/냉장/원산지 별도표기) L.POINT 각 2,000원 할인
    ※살치살/토시살/부채살
    레드 자몽(5~10입/봉/이스라엘산) 7,990원
    창녕 양파품은 한우불고기 (700g/냉장/원산지 별도표기) L.POINT 50% 17,900원
    냉동 새우살 묶음팩 3종 (각 250g×2/원산지 별도표기) L.POINT 30% 16,730원
    1+1 동원 양반 참기름김/참숯구이김/들기름김(각 4g×16봉/원산지 별도표기) 각12,980원
    ※교차구매 가능
    2개 이상 50% 코주부 징기스칸 육포 순한맛/매운맛 (각 130g/원산지 별도표기) 2팩 이상 구매시 1팩당 각7,450원
    ※교차구매 가능 ※1개 구매시 14,900원
    하나사면 하나 더 1+1
    1+1 목우촌 주부9단 육즙팡팡 프랑크(350g) 8,990원
    1+1 풀무원 24시 깔끔/얼큰 즉석우동 (321.2g/328g) 각6,990원
    ※교차구매 가능
    1+1 그릭/떠먹는 요구르트 16종(상품별 상이) 각3,780~5,290
    ※빙그레 5종/남양 3종/다논 6종/연세 2종
    ※브랜드별 동일가격간 교차구매 가능
    1+1 오뚜기 지역식 국물요리 5종(각 500g) ※교차구매 가능 각7,980원
    1+1 하림 팝콘치킨/통가슴살 치킨너겟 (650g/500g) 각10,990원
    ※교차구매 가능
    1+1 롯데 칠성/펩시 페트 5종 (각 1.25L) 각3,580원
    ※교차구매 가능
    1+1 매일 뼈로가는 칼슘치즈 (180g) 7,190원
    1+1 미에로 화이바/맑은매실 (각 1.2L) 각3,480원
    ※교차구매 가능
    1+1 백설 고소함가득 참기름(320ml) 10,900원
    1+1 웅진 티즐제로 2종(각 1.5L) 각3,680원
    ※피치우롱티/딸기루이보스 ※교차구매 가능
    1+1 페리오 뉴후레쉬 알파 (185g×4) 9,900원
    동원 비엔나 소세지기획 (300g×2) 6,480원
    빙그레 그라시아 쿠앤크/초코, 투게더(스토로베리)/프럴린&아몬드, 요맘때 허니플레인(상품별 상이) 2개 구매시 9,900원
    ※1개 구매시 각5,990원
    버드와이저/스텔라(캔 473ml×6) 2,000원 할인 각12,900원
    아임비타 멀티비타민 이뮨플러스 (23.5g×7병) 5,000원 할인 11,900원
    맥심 모카골드 제로슈거(20입/50입) 5,480/12,480원
    글루타티온 마스크팩(10매) 6,900원
    유한락스 세정제 2종(상품별 상이) ※욕실청용/곰팡이용 각6,900원
    2개 이상 50%
    나무야 순수 3겹 데코 화장지 (27m×36롤) 2개 이상 구매시 1개당 각11,900원
    ※1개 구매시 23,800원
    퐁퐁 친환경 주방세제 2종 (각 1.2L) 각2,400원
    ※오렌지/코코넛
    2/13 목 2/14 금 마지막 찬스! 발렌타인데이 해당상품 1만 8천원 이상 결제시 5,000원 롯데모바일 상품권 증정!
    ※해당 브랜드(8개사) : 롯데웰푸드/매일유업/농심/한국마즈/해태제과/크라운제과/오리온/엔제이
    ※기간 : 1/31(금)~2/14(금) ※L.POINT 회원에 한함 ※행사상품에 한함
    ※개인정보 활용 및 SMS 수신 동의 고객에 한함 ※롯데멤버스 제 3자 개인정보 제공 동의자에 한함
    ※단일 결제건에 한함 ※영수증 합산 불가 ※2/21(금) 문자 순차 발송
    롯데 프리미엄 가나 레몬 치즈 타르트/베리무스케익(각 68g) 각3,490원
    봄맞이 리빙 플렉스
    2개 이상 50%
    퍼실/퍼울/버넬 8종 (상품별 상이) 2개 이상 구매시 1개당 각3,450~14,950원
    ※교차구매 가능 ※1개 구매시 각6,900~29,900원
    2개 이상 50%
    프릴 주방세제 5종 (상품별 상이) 2개 이상 구매시 1개당 각4,750원
    ※교차구매 가능 ※1개 구매시 각9,500원
    오늘 뭐 먹지? ※델리운영점에 한함
    한통가득 탕수육(팩/원산지 별도표기) 12,990원
    왕새우 튀김 (8입/15입, 원산지 별도표기) 5,490/9,990원
    해물양장피 (1팩/원산지 별도표기) 10,900원

    ※정상가란? 2025년 1월 23일(목) ~ 2월12일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    
  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getSuperData = () => {
  const dataArray = [];
  superData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getSuperNewData = () => {
  const dataArray = [];
  superNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L209: {
    title: "스마트전단지-슈퍼209",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202502_2/02",
    contents: getSuperData(),
  },
  L309: {
    title: "스마트전단지-슈퍼309",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202502_3/02",
    contents: getSuperNewData(),
  },
};

export default data;
